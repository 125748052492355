import { TFunction } from 'i18next';
import { ColumnType } from 'antd/lib/table';

import { MSClaims } from '../../services/backendService';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const adminUserTableColumns = (t: TFunction): ColumnType<any>[] => [
  {
    title: t('Id'),
    dataIndex: 'uid',
    key: 'uid',
  },
  {
    title: t('Name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('Email'),
    dataIndex: 'email',
    key: 'email',
    sorter: (a, b) => {
      if (a.email < b.email) {
        return -1;
      }
      if (a.email > b.email) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: t('Created at'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a, b) => {
      return a.creationTime.diff(b.creationTime);
    },
  },
  {
    title: t('Role'),
    dataIndex: 'role',
    key: 'role',
    filters: [
      {
        text: t('Admin'),
        value: 'admin',
      },
      {
        text: t('Mentor'),
        value: 'mentor',
      },
      {
        text: t('Student'),
        value: 'student',
      },
      { text: t('Requesting mentor'), value: 'requestingMentorAccess' },
    ],
    onFilter: (
      value,
      record: { roles: MSClaims; hasOpenMentorAccessRequest: boolean },
    ) => {
      if (value === 'requestingMentorAccess') return !!record.hasOpenMentorAccessRequest;
      return record.roles[value as 'admin' | 'mentor' | 'student'] === true;
    },
  },
  {
    title: t('Nickname'),
    dataIndex: 'nickName',
    key: 'nickName',
  },
  {
    title: t('Actions'),
    dataIndex: 'actions',
    key: 'actions',
  },
];

export const adminMentorDetailTableColumns = [
  {
    title: '',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: '',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: '',
    dataIndex: 'link',
    key: 'link',
  },
];
