import { combineEpics } from 'redux-observable';
import { filter, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { from } from 'rxjs';
import Axios from 'axios-observable';

import { catchHandler } from '../utils';
import { PUBLIC_DATA_RECEIVED, getHasCustomLogo } from '../Server';

import { receiveBlob, receiveCustomLogo } from './actions';
import { getIsLoading, getBlob } from './reducer';
import { BLOB_REQUESTED, CUSTOM_LOGO_REQUESTED } from './actionTypes';

import { RootEpic } from '..';

const fetchBlob = (url: string) =>
  Axios.request({
    method: 'get',
    url,
    responseType: 'blob',
  }).pipe(map(res => res.data));

export const onBlobRequested: RootEpic = (action$, state$, { files }) =>
  action$.pipe(
    filter(isOfType(BLOB_REQUESTED)),
    filter(
      ({ payload: { id } }) =>
        !getIsLoading(id, state$.value) || getBlob(id, state$.value) === undefined,
    ),
    mergeMap(({ payload: { chatRoomId, id } }) => {
      return from(files.getImageDownloadUrl(chatRoomId, id)).pipe(
        mergeMap(fetchBlob),
        map(blob => receiveBlob(blob, id)),
      );
    }),
    catchError(catchHandler),
  );

export const onCustomLogoRequested: RootEpic = (action$, state$, { files }) =>
  action$.pipe(
    filter(isOfType([PUBLIC_DATA_RECEIVED, CUSTOM_LOGO_REQUESTED])),
    filter(() => !!getHasCustomLogo(state$.value)),
    switchMap(() => {
      return from(files.admin.getCustomLogoUrl()).pipe(
        mergeMap(fetchBlob),
        map(blob => receiveCustomLogo(blob)),
        catchError(catchHandler),
      );
    }),
    catchError(catchHandler),
  );
export const blobsRootEpic = combineEpics(onBlobRequested, onCustomLogoRequested);
