import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './App';
import { configureStore } from './store';
import ErrorBoundary from './providers/ErrorBoundary';
import './singletons/i18n';
import { alertNewVersionAvailable } from './modules';
const { store, notifications, auth } = configureStore();

ReactDOM.render(
  <>
    <ErrorBoundary>
      <Provider store={store}>
        <App authProvider={auth.getAuthProvider()} />
      </Provider>
    </ErrorBoundary>
  </>,
  document.getElementById('root'),
);

if ('serviceWorker' in navigator) {
  serviceWorker.register(notifications, {
    onUpdate: reg => store.dispatch(alertNewVersionAvailable(reg)),
  });
}
