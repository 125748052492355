import firebase from 'firebase/app';
import * as Sentry from '@sentry/browser';
import 'firebase/auth';
import { MsalAuthProvider, LoginType } from 'react-aad-msal';

import { Metrics } from '../metrics';
export default class Authentication {
  private auth: firebase.auth.Auth;
  private authProvider: MsalAuthProvider;
  constructor({
    tenant,
    clientId,
    fallbackPolicy,
  }: {
    tenant: string;
    clientId: string;
    fallbackPolicy: string;
  }) {
    this.auth = firebase.auth();

    const signInPolicy = fallbackPolicy;
    const tenantSubdomain = tenant.split('.')[0];
    const signInAuthority = `https://${tenantSubdomain}.b2clogin.com/tfp/${tenant}/${signInPolicy}`;
    // Msal Configurations
    const config = {
      auth: {
        authority: signInAuthority,
        clientId,
        redirectUri: `${window.location.origin}`,
        validateAuthority: false,
      },

      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    };

    // Authentication Parameters
    const authenticationParameters = {
      // scopes: ['openid', 'email'],
    };

    // Options
    const options = {
      loginType: LoginType.Redirect,
      tokenRefreshUri: `${window.location.origin}/auth.html`,
    };
    this.authProvider = new MsalAuthProvider(
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      config,
      authenticationParameters,
      options,
    );
    this.auth.useDeviceLanguage();
  }

  public getAuthProvider = () => this.authProvider;

  public getAuth = () => this.auth;

  public sendPasswordResetEmail = async (email: string) => {
    const actionCodeSettings: firebase.auth.ActionCodeSettings = {
      url: window.location.origin,
    };
    this.auth.useDeviceLanguage();
    if (process.env.REACT_APP_TEST_E2E !== 'true')
      return this.auth.sendPasswordResetEmail(email, actionCodeSettings);
  };

  public signInWithCustomToken = (token: string) => {
    try {
      return this.auth.signInWithCustomToken(token);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      Sentry.captureException(e);
      throw e;
    }
  };

  public deleteUser = async () => {
    const user = this.auth.currentUser;
    if (!user) throw new Error('No user to delete');
    await user.delete();
  };

  signin = async (email: string, password: string) => {
    const result = await this.auth.signInWithEmailAndPassword(email, password);
    Metrics.getLogger().logEvent('signInWithEmailAndPassword');
    return result;
  };
}
