import * as Sentry from '@sentry/browser';
import {
  filter,
  mergeMap,
  mapTo,
  catchError,
  switchMap,
  tap,
  retryWhen,
  delay,
  take,
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { combineEpics } from 'redux-observable';
import { from, of, Observable, Observer, empty, merge } from 'rxjs';

import { catchHandler } from '../utils';
import { Metrics } from '../../services/metrics';
import { getTenantIdFromSubdomain } from '../../utils/navigatorUtils';
import { getUserId } from '../Auth';

import {
  SIGN_IN,
  SIGN_OUT,
  REGISTER,
  LOAD_AUTH,
  RESET_PASSWORD_EMAIL,
  DELETE_USER,
  GET_OAUTH_TOKEN,
  INIT_FRESHCHAT,
} from './actionTypes';
import {
  signInSuccess,
  signOutSuccess,
  registerSuccess,
  authLoadedNoUser,
  setUser,
  sendResetPasswordEmailSuccess,
  signInError,
  registerError,
  deleteUserSuccess,
  deleteUserFailure,
  getOAuthTokenSuccess,
  getOAuthTokenFailureRegister,
  getOAuthTokenFailureLogin,
  resetAction,
  initFreshchat,
} from './actions';

import { RootEpic } from '..';

const getVersion = () => process.env.REACT_APP_VERSION || '-1';

export const signinEpic: RootEpic = (action$, _, { backendService, auth }) =>
  action$.pipe(
    filter(isOfType(SIGN_IN)),
    mergeMap(({ payload: { email, password } }) =>
      from(backendService.verifyUser(email)).pipe(
        mergeMap(exists => {
          if (!exists) return of(signInError('Invalid username or password for tenant'));
          return from(auth.signin(email, password)).pipe(
            tap(() => Metrics.getLogger().logEvent('signInSuccess')),
            mapTo(signInSuccess()),
            catchError((error: Error) => {
              Metrics.getLogger().logEvent('signInError', {
                data: error.message,
              });
              return of(
                signInError(
                  'The username or password is wrong. Could not login. Please try again.',
                ),
              );
            }),
          );
        }),
        catchError((error: Error) => {
          Sentry.captureException(error);
          Metrics.getLogger().logEvent('verifyUserError', {
            data: error.message,
          });
          return of(
            signInError(
              'The username or password is wrong. Could not login. Please try again.',
            ),
          );
        }),
      ),
    ),
  );

export const registerEpic: RootEpic = (action$, _, { backendService }) =>
  action$.pipe(
    filter(isOfType(REGISTER)),
    mergeMap(({ payload: { email, password, firstName, lastName } }) =>
      from(backendService.signup(email, password, firstName, lastName)).pipe(
        tap(() => {
          Metrics.getLogger().logEvent('registerSuccess');
        }),
        mergeMap(() => of(registerSuccess())),
        catchError((error: Error) => {
          Metrics.getLogger().logEvent('registerError', {
            data: error.message,
          });
          return of(registerError(error.message));
        }),
      ),
    ),
  );

export const onSendResetPasswordEmail: RootEpic = (action$, state$, { auth }) =>
  action$.pipe(
    filter(isOfType(RESET_PASSWORD_EMAIL)),
    mergeMap(action =>
      from(auth.sendPasswordResetEmail(action.payload)).pipe(
        mapTo(sendResetPasswordEmailSuccess()),
        catchError(() => of(sendResetPasswordEmailSuccess())),
      ),
    ),
  );

export const signoutEpic: RootEpic = (action$, state$, { database, auth }) =>
  action$.pipe(
    filter(isOfType(SIGN_OUT)),
    tap(() => {
      Metrics.logout(getTenantIdFromSubdomain(window.origin));
    }),
    mergeMap(() =>
      merge(
        of(resetAction()),
        from(database.usertype.setAvailable(false, getUserId(state$.value)!)).pipe(
          tap(() => auth.getAuthProvider().logout()),
          mapTo(signOutSuccess()),
          catchError(catchHandler),
        ),
      ),
    ),
    catchError(catchHandler),
  );

const getOAuthTokenEpic: RootEpic = (action$, state$, { backendService, auth }) =>
  action$.pipe(
    filter(isOfType(GET_OAUTH_TOKEN)),
    switchMap(({ payload: { code, provider, state } }) =>
      backendService.getOAuthToken(code, provider).pipe(
        mergeMap(token =>
          backendService.getOAuthUserInfo(token.access_token, provider, state).pipe(
            mergeMap(jwt => from(auth.signInWithCustomToken(jwt.customToken))),
            tap(() => {
              Metrics.getLogger().logEvent('oAuth.success', { provider, state });
            }),
            mapTo(getOAuthTokenSuccess(token)),
            catchError(e => {
              Metrics.getLogger().logEvent('oAuth.failed', { provider, state });
              return of(
                state === 'login'
                  ? getOAuthTokenFailureLogin(e.message)
                  : getOAuthTokenFailureRegister(e.message),
              );
            }),
          ),
        ),
        catchError(e => {
          Metrics.getLogger().logEvent('oAuth.failed', { provider, state });
          return of(
            state === 'login'
              ? getOAuthTokenFailureLogin(e.message)
              : getOAuthTokenFailureRegister(e.message),
          );
        }),
      ),
    ),
    catchError(catchHandler),
  );

export const deleteUserEpic: RootEpic = (action$, _, { auth }) =>
  action$.pipe(
    filter(isOfType(DELETE_USER)),
    mergeMap(() =>
      of(auth.deleteUser()).pipe(
        mergeMap(() => of(deleteUserSuccess())),
        catchError(() => of(deleteUserFailure())),
      ),
    ),
  );
export const freshchatEpic: RootEpic = (action$, _) =>
  action$.pipe(
    filter(isOfType(INIT_FRESHCHAT)),
    tap(({ payload: { id } }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      //@ts-ignore
      if (!window.fcWidget) throw Error('Freshchat not initialized yet');
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      //@ts-ignore
      window.fcWidget.init({
        token: 'e8bdccc6-780d-4ed4-bfd5-6bf5b7b84efa',
        host: 'https://wchat.freshchat.com',
        tags: ['snapmentor', getTenantIdFromSubdomain(window.origin)],
        config: {
          headerProperty: {
            hideChatButton: true,
          },
          cssNames: {
            widget: 'snapmentor_fc_frame',
            open: 'snapmentor_fc_open',
            expanded: 'snapmentor_fc_expanded',
          },
        },
        externalId: id,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      //@ts-ignore
    }),
    retryWhen(errors => errors.pipe(delay(1000), take(15))),
    mergeMap(() =>
      action$.pipe(
        filter(isOfType(SIGN_OUT)),
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        //@ts-ignore
        mergeMap(() => from(window.fcWidget.user.clear())),
      ),
    ),
    mergeMap(() => empty()),
    catchError(e => {
      // eslint-disable-next-line no-console
      console.error(e);
      return empty();
    }),
  );

export const onLoadAuth: RootEpic = (action$, state$, { auth }) =>
  action$.pipe(
    filter(isOfType(LOAD_AUTH)),
    tap(() => {
      // setting user as unidentified before fetching
      Metrics.setUser(getTenantIdFromSubdomain(window.origin));
      Sentry.configureScope(scope => {
        scope.setTag('version', getVersion());
        scope.setUser({ id: 'unidentified' });
      });
    }),
    switchMap(() => {
      const coldObs$ = new Observable((observer: Observer<firebase.User | null>) => {
        return auth.getAuth().onAuthStateChanged(
          user => observer.next(user),
          err => observer.error(err),
          () => observer.complete(),
        );
      });
      return coldObs$.pipe(
        tap(user => {
          const tenantId = getTenantIdFromSubdomain(window.origin);
          if (user) {
            Metrics.setUser(tenantId, getUserId(state$.value)!);
            Sentry.configureScope(scope => {
              scope.setTag('version', getVersion());
              scope.setTag('tenantId', tenantId);
            });
          } else {
            // set user as unidentified when logging out
            Metrics.setUser(getTenantIdFromSubdomain(window.origin));
          }
        }),
        switchMap(user => {
          if (!user) {
            return of(authLoadedNoUser());
          } else {
            return of(setUser(user, {}), initFreshchat(getUserId(state$.value)!));
          }
        }),
      );
    }),
    catchError((e, obs) => {
      Sentry.captureException(e);
      return obs;
    }),
  );

export const rootUserEpic = combineEpics(
  freshchatEpic,
  signinEpic,
  signoutEpic,
  registerEpic,
  onLoadAuth,
  onSendResetPasswordEmail,
  deleteUserEpic,
  getOAuthTokenEpic,
);
