import firebase from 'firebase/app';
import 'firebase/messaging';
import * as Sentry from '@sentry/browser';
import { from, empty, Subject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export default class Notifications {
  messaging: firebase.messaging.Messaging | null = null;
  constructor(vapidKey: string) {
    try {
      if (firebase.messaging.isSupported()) {
        this.messaging = firebase.messaging();
        this.messaging.usePublicVapidKey(vapidKey);
        // eslint-disable-next-line no-console
        console.log('Messaging is supported');
      } else {
        // eslint-disable-next-line no-console
        console.log('Messaging is not supported');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
      // eslint-disable-next-line no-console
      Sentry.captureException(e);
    }
  }

  isSupported = () => firebase.messaging.isSupported();
  getMessaging = () => this.messaging;

  getToken = () => {
    if (!this.messaging) {
      // eslint-disable-next-line no-console
      console.error('Token cannot be found. Messaging not initalized');
      return empty();
    }
    return from(this.messaging.getToken()).pipe(
      catchError(e => {
        // eslint-disable-next-line no-console
        console.log(e.message);
        Sentry.captureException(e);
        return empty();
      }),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onPushNoticationMessage = (): Observable<any> => {
    const sub = new Subject();
    // TODO ADD METRIC
    // TODO technical debt. Handle push notifications without memory leaks

    this.messaging &&
      this.messaging.onMessage(message => {
        // TODO ADD METRIC
        sub.next(message);
      });
    return sub.asObservable();
  };
}
