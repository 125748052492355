import axios from 'axios-observable';
import { map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as Sentry from '@sentry/browser';

import { Claims, ById } from '../modules';

import { ChatRoomEntity, UserType, Message, MatchmakingElement } from './db';
import { Metrics } from './metrics';

export interface ClaimDiscussionResponse {
  chatRoomId: string;
}

export interface NamesByIdsResponse {
  [id: string]: {
    id: string;
    name?: string;
  };
}
export interface ClaimAbandonedSessionResponse {
  chatRoomId: string;
}

const SentryLogger = (e: Error) => {
  Sentry.captureException(e);
  throw e;
};

const getAuthHeader = (token: string) => ({ Authorization: `Bearer ${token}` });

export class BackendService {
  rootUrl: string;
  localUrl = 'http://localhost:5000';
  localExternalApiUrl = 'http://localhost:5001';
  externalAPIUrl = 'https://api-test.snapmentor.no';
  constructor(rootUrl: string, externalApiUrl: string) {
    this.rootUrl = `${rootUrl}/api`;
    this.externalAPIUrl = externalApiUrl;
    if (process.env.NODE_ENV !== 'production') {
      this.rootUrl = this.localUrl;
      this.externalAPIUrl = this.localExternalApiUrl;
    }
  }

  public getCustomToken = (idToken: string) =>
    axios
      .get(`${this.externalAPIUrl}/v0/customFirebaseToken`, {
        headers: getAuthHeader(idToken),
      })
      .pipe(map(res => res.data));
  public claimSession = ({ id, token }: { id: string; token: string }) => {
    return axios
      .request<ClaimDiscussionResponse>({
        method: 'POST',
        url: `${this.rootUrl}/claimSession`,
        data: {
          id,
        },
        headers: getAuthHeader(token),
      })
      .pipe(
        map(res => res.data),
        catchError(SentryLogger),
      );
  };

  public claimAbandonedSession = ({
    sessionId,
    token,
  }: {
    sessionId: string;
    token: string;
  }) => {
    return axios
      .request<ClaimAbandonedSessionResponse>({
        method: 'POST',
        url: `${this.rootUrl}/claimAbandonedSession`,
        data: {
          id: sessionId,
        },
        headers: getAuthHeader(token),
      })
      .pipe(
        map(res => res.data),
        catchError(SentryLogger),
      );
  };

  public namesByIds = (ids: string[], token: string) => {
    const filtered = ids.filter(id => id !== undefined);
    const all = new Set(filtered);

    if (filtered.length === 0) return of({});
    const query = `ids=${Array.from(all).join(',')}`;
    return axios
      .request<NamesByIdsResponse>({
        method: 'GET',
        url: `${this.rootUrl}/namesByIds?${query}`,
        headers: getAuthHeader(token),
      })
      .pipe(
        map(res => res.data),
        catchError(SentryLogger),
      );
  };

  public getOAuthToken = (code: string, provider: OAuthProvider) => {
    const redirects = {
      vipps: `${window.location.origin}/vipps-login`,
      feide: `${window.location.origin}/feide-callback`,
      google: `${window.location.origin}/google-login`,
    };
    const redirect = redirects[provider];
    return axios
      .request({
        method: 'GET',
        url: `${this.rootUrl}/oauthToken?provider=${provider}&code=${code}&redirect=${redirect}`,
      })
      .pipe(
        map(res => res.data),
        catchError(SentryLogger),
      );
  };

  public getOAuthUserInfo = (
    accessToken: string,
    provider: OAuthProvider,
    state: string,
  ) => {
    return axios
      .request<{ customToken: string }>({
        method: 'GET',
        url: `${this.rootUrl}/oauthUserInfoState?access_token=${accessToken}&provider=${provider}&state=${state}`,
      })
      .pipe(
        map(res => res.data),
        catchError(e => {
          Metrics.getLogger().logEvent('oauth_user_info', e.response.data);
          throw new Error(e.response.data.error.message);
        }),
      );
  };

  public getAllUsers = (token: string) => {
    return axios
      .get<{ users: RoleUser[] }>(`${this.rootUrl}/users`, {
        headers: getAuthHeader(token),
      })
      .pipe(
        map(res => res.data.users),
        catchError(SentryLogger),
      );
  };

  public deleteUser = (uid: string, token: string) =>
    axios.delete(`${this.rootUrl}/users/${uid}`, { headers: getAuthHeader(token) }).pipe(
      map(res => res.data),
      catchError(SentryLogger),
    );

  public setClaims = (uid: string, claims: MSClaims, token: string) =>
    axios
      .post<RoleUser>(
        `${this.rootUrl}/setClaims`,
        {
          uid,
          claims,
        },
        { headers: getAuthHeader(token) },
      )
      .pipe(
        map(res => res.data),
        catchError(SentryLogger),
      );

  public signup = (
    username: string,
    password: string,
    firstName: string,
    lastName: string,
  ) =>
    axios
      .post(`${this.rootUrl}/users/register`, {
        username,
        password,
        firstName,
        lastName,
      })
      .pipe(
        map(res => res.data),
        catchError(e => {
          Metrics.getLogger().logEvent('register_error', e.response.data);
          throw new Error(e.response.data.error.message);
        }),
      );

  public fetchUnclaimedQueue = (token: string) =>
    axios
      .get<MatchMakingWithChatRoom[]>(`${this.rootUrl}/unClaimedQueue`, {
        headers: getAuthHeader(token),
      })
      .pipe(
        map(res => res.data),
        catchError(SentryLogger),
      );

  public verifyUser = (email: string) =>
    axios.get<{ ok: boolean }>(`${this.rootUrl}/verifyUser?email=${email}`).pipe(
      map(res => res.data.ok),
      catchError(SentryLogger),
    );

  apiKeys = {
    create: (uid: string, token: string) =>
      axios
        .post<ApiKey>(
          `${this.rootUrl}/api-keys/`,
          { uid },
          {
            headers: getAuthHeader(token),
          },
        )
        .pipe(
          map(res => res.data),
          catchError(SentryLogger),
        ),
    list: (token: string) =>
      axios
        .get<{ data: ApiKey[] }>(`${this.rootUrl}/api-keys/`, {
          headers: getAuthHeader(token),
        })
        .pipe(
          map(res => res.data.data),
          catchError(SentryLogger),
        ),
    delete: (token: string, uid: string, apiKey: string) =>
      axios
        .post<void>(
          `${this.rootUrl}/api-keys/delete`,
          {
            uid,
            apikey: apiKey,
          },
          {
            headers: getAuthHeader(token),
          },
        )
        .pipe(
          map(res => res.data),
          catchError(SentryLogger),
        ),
  };

  payments = {
    getOAuthState: (token: string) =>
      axios
        .get<{ state: string }>(`${this.rootUrl}/payments/connected-account/create`, {
          headers: getAuthHeader(token),
        })
        .pipe(
          map(res => res.data.state),
          catchError(SentryLogger),
        ),
    confirmRegistration: (code: string, state: string, token: string) =>
      axios
        .get<PaymentsConfirmRegistrationType>(
          `${this.rootUrl}/payments/connected-account/confirm?code=${code}&state=${state}`,
          {
            headers: getAuthHeader(token),
          },
        )
        .pipe(
          map(res => res.data),
          catchError(SentryLogger),
        ),
    createCustomer: (
      paymentMethodId: string,
      token: string,
      name?: string,
      email?: string,
    ) =>
      axios
        .post<void>(
          `${this.rootUrl}/payments/customers`,
          { paymentMethodId, name, email },
          {
            headers: getAuthHeader(token),
          },
        )
        .pipe(
          map(res => res.data),
          catchError(SentryLogger),
        ),
    createSubscription: (planId: string, uid: string, token: string) =>
      axios
        .post<{
          subscription: {
            latest_invoice: {
              payment_intent?: { client_secret: string; status: string };
            };
          };
        }>(
          `${this.rootUrl}/payments/customers/${uid}/subscriptions`,
          { availableSubscriptionId: planId },
          {
            headers: getAuthHeader(token),
          },
        )
        .pipe(
          map(res => res.data.subscription),
          catchError(SentryLogger),
        ),
    getCustomer: (uid: string, token: string) =>
      axios
        .get<APIPaymentCustomer>(`${this.rootUrl}/payments/customers/${uid}`, {
          headers: getAuthHeader(token),
        })
        .pipe(map(res => res.data)),
    cancelSubscription: (subscriptionId: string, uid: string, token: string) =>
      axios
        .post<APIPaymentCustomer>(
          `${this.rootUrl}/payments/customers/${uid}/subscription/${subscriptionId}/cancel`,
          {},
          {
            headers: getAuthHeader(token),
          },
        )
        .pipe(
          map(res => res.data),
          catchError(SentryLogger),
        ),
    reactivateSubscription: (subscriptionId: string, uid: string, token: string) =>
      axios
        .post<APIPaymentCustomer>(
          `${this.rootUrl}/payments/customers/${uid}/subscription/${subscriptionId}/reactivate`,
          {},
          {
            headers: getAuthHeader(token),
          },
        )
        .pipe(
          map(res => res.data),
          catchError(SentryLogger),
        ),
    updatePaymentDetails: (paymentMethodId: string, uid: string, token: string) =>
      axios
        .post<APIPaymentCustomer>(
          `${this.rootUrl}/payments/customers/${uid}/paymentDetails/update`,
          { paymentMethodId },
          {
            headers: getAuthHeader(token),
          },
        )
        .pipe(
          map(res => res.data),
          catchError(SentryLogger),
        ),
    plans: (token: string) =>
      axios
        .get<APIPlans>(`${this.rootUrl}/payments/plans`, {
          headers: getAuthHeader(token),
        })
        .pipe(
          map(res => res.data),
          catchError(SentryLogger),
        ),
    availableSubscriptions: (token: string) =>
      axios
        .get<{ data: AvailableSubscriptionDTO[] }>(
          `${this.rootUrl}/payments/availableSubscriptions`,
          {
            headers: getAuthHeader(token),
          },
        )
        .pipe(
          map(res => res.data.data),
          catchError(SentryLogger),
        ),
    canCreateDiscussion: (uid: string, token: string) =>
      axios
        .get<{ ok: boolean }>(
          `${this.rootUrl}/payments/customers/${uid}/canCreateDiscussion`,
          {
            headers: getAuthHeader(token),
          },
        )
        .pipe(
          map(res => res.data.ok),
          tap(ok => Metrics.getLogger().logEvent('canCreateDiscussion', { ok })),
          catchError(e => {
            Metrics.getLogger().logEvent('canCreateDiscussion', {
              ok: false,
              error: e.message,
            });
            throw e;
          }),
        ),
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type APIPlans = { plans: any; product?: any }[];

type StripeTimestamp = number | null;

type APIPaymentSubscription = {
  billing_cycle_ancor: number;
  canceled_at: StripeTimestamp;
  cancel_at_period_end: boolean;
  current_period_start: StripeTimestamp;
  current_period_end: StripeTimestamp;
  customer: string;
  status: string;
  trial_start: StripeTimestamp;
  id: string;
  items: {
    data: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      price: any;
    }[];
  };
};

export type APIPaymentCustomer = {
  subscriptions: {
    data: APIPaymentSubscription[];
  };
  defaultPaymentMethod: {
    id: string;
    card: {
      brand: string;
      exp_month: number;
      exp_year: number;
      last4: string;
    };
  };
};

export type PaymentsConfirmRegistrationType = { success: boolean; accountId: string };

export type MatchMakingWithChatRoom = MatchmakingElement & {
  chatRoom: ChatRoomEntity;
  messages: Message[];
};

export type OAuthProvider = 'vipps' | 'feide' | 'google';

export type ClaimsUser = {
  uid: string;
  customClaims: Claims;
  email?: string;
  name?: string;
  metadata: ById<string>;
  userType?: UserType;
};

export type APIClaims = {
  mentor: boolean;
  tenantAdmin: boolean;
};

export interface VippsToken {
  access_token: string;
  expires_in: number;
  id_token: string;
  scope: 'openid';
  token_type: 'bearer';
}

export type ApiKey = {
  uid: string;
  tenantId: string;
  timestamp: firebase.firestore.Timestamp;
  apiKey: string;
};

export type AvailableSubscriptionDTO = {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  product: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prices: any[];
  unit?: { type: 'minutes'; amount: number };
};

export type MSClaims = {
  admin: boolean;
  mentor: boolean;
  student: boolean;
};
export type RoleUser = {
  id: string;
  name: string;
  createdAt: string;
  email?: string;
  roles: MSClaims;
};
