import {
  AuthenticationState,
  AuthenticationActions,
  AccessTokenResponse,
  IdTokenResponse,
  IAccountInfo,
} from 'react-aad-msal';

import { AppState } from '../rootReducer';

import { RootAction } from '..';

export type AuthState = {
  initializing: boolean;
  initialized: boolean;
  idToken?: IdTokenResponse;
  accessToken?: AccessTokenResponse;
  state: AuthenticationState;
  account?: IAccountInfo;
  redirectOnResetPassword?: boolean;
  errorCode?: string;
  errorMessage?: string;
};

const initialState: AuthState = {
  initializing: false,
  initialized: false,
  state: AuthenticationState.Unauthenticated,
};

export const authReducer = (
  state: AuthState = initialState,
  action: RootAction,
): AuthState => {
  switch (action.type) {
    case AuthenticationActions.Initializing:
      return {
        ...state,
        initializing: true,
        initialized: false,
      };
    case AuthenticationActions.Initialized:
      return {
        ...state,
        initializing: false,
        initialized: true,
      };
    case AuthenticationActions.AcquiredIdTokenSuccess:
      return {
        ...state,
        idToken: action.payload,
      };
    case AuthenticationActions.AcquiredAccessTokenSuccess:
      return {
        ...state,
        accessToken: action.payload,
      };
    case AuthenticationActions.AcquiredAccessTokenError:
      return {
        ...state,
        accessToken: undefined,
      };
    case AuthenticationActions.LoginSuccess:
      return {
        ...state,
        account: action.payload,
      };
    case AuthenticationActions.LoginError:
      return {
        ...state,
        errorCode: action.payload.errorCode,
        errorMessage: action.payload.errorMessage,
        idToken: undefined,
        accessToken: undefined,
        account: undefined,
      };
    case AuthenticationActions.AcquiredIdTokenError:
    case AuthenticationActions.LogoutSuccess:
      return { ...state, idToken: undefined, accessToken: undefined, account: undefined };
    case AuthenticationActions.AuthenticatedStateChanged:
      return {
        ...state,
        state: action.payload,
      };
    case 'AUTH/RESET_PASSWORD_REDIRECT':
      return {
        ...state,
        redirectOnResetPassword: true,
      };
    default:
      return state;
  }
};

// selectors

export const getIsSignedIn = (state: AppState) =>
  state.auth.initialized &&
  state.auth.state === AuthenticationState.Authenticated &&
  !!state.user.auth;

export const getIsAuthInitialised = (state: AppState) => state.auth.initialized;
export const getIsAuthenticated = (state: AppState) =>
  state.auth.state === AuthenticationState.Authenticated;

export const getUserId = (state: AppState) => state.auth.idToken?.idToken.subject;
export const getEmail = (state: AppState) => state.auth.idToken?.idToken.claims.email;
export const getIsMentor = (state: AppState) =>
  !!state.auth.idToken?.idToken.claims.extension_role_mentor;
export const getIsTenantAdmin = (state: AppState) =>
  !!state.auth.idToken?.idToken.claims.extension_role_admin;
export const getIsStudent = (state: AppState) =>
  !!state.auth.idToken?.idToken.claims.extension_role_student;

export const getIdToken = (state: AppState) => state.auth.idToken?.idToken.rawIdToken;
export const shouldRedirectOnResetPassword = (state: AppState) =>
  state.auth.redirectOnResetPassword;

export const getErrorMessage = (state: AppState) => {
  const { errorMessage, errorCode } = state.auth;
  return {
    errorMessage,
    errorCode,
  };
};
