import { combineEpics } from 'redux-observable';
import { filter, mergeMap, catchError, mapTo, tap, delay } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { from, of } from 'rxjs';
import * as Sentry from '@sentry/browser';

import { AdditionalInfo, ChatRoomEntity, Message } from '../../services/db';
import { addChatRoomToUser, selectChatRoom } from '../ChatRooms';
import { Metrics } from '../../services/metrics';
import { canCreateDiscussionReset } from '../Payments';
import { getUserId } from '../Auth';

import { START_DISCUSSION } from './actionTypes';
import { toChatRoomAdditionalInfo, getCreateDiscussion } from './reducer';
import { startDiscussionFailed, startDiscussionSuccess } from './actions';

import { RootEpic } from '..';

const toInitialChatRoom = (
  userId: string,
  additionalInfo: AdditionalInfo,
): ChatRoomEntity => ({
  userId,
  isComplete: false,
  additionalInfo,
});

export const onDiscussionReadyCreateEpic: RootEpic = (action$, state$, { db, files }) =>
  action$.pipe(
    filter(isOfType(START_DISCUSSION)),
    mergeMap(() => {
      const uid = getUserId(state$.value)!;
      const additionalInfo = toChatRoomAdditionalInfo(state$.value);
      const discussionData = getCreateDiscussion(state$.value);
      const chatRoom = toInitialChatRoom(uid, additionalInfo);
      const imageId = new Date().getTime().toString();
      const messages: Message[] = [
        {
          createdBy: uid,
          text: discussionData.textMessage,
          timeStamp: new Date().getTime(),
        },
      ];
      if (discussionData.imageId)
        messages.unshift({
          createdBy: getUserId(state$.value)!,
          imageId,
          timeStamp: new Date().getTime(),
        });
      const chat: ChatRoomEntity = {
        ...chatRoom,
        messages: messages.reduce((acc, cur, i) => ({ ...acc, [i]: cur }), {}),
      };
      return from(db.createChatRoomId()).pipe(
        mergeMap(key => {
          if (!discussionData.imageId) return of({ key });
          return from(
            files.uploadDataUrlImage(imageId, key!, discussionData.imageId),
          ).pipe(
            mapTo({ key }),
            catchError(e => {
              Sentry.captureException(e);
              return of({ key });
            }),
          );
        }),
        mergeMap(({ key }) => from(db.addChatRoom(chat, key!))),
        mergeMap(({ key }) =>
          from(db.createChatRoomExtraInfo(key!, getUserId(state$.value)!)),
        ),
        tap(({ key }) => {
          Metrics.getLogger().logEvent('chatRoomCreatedSuccess', {
            data: key,
          });
        }),
        delay(2000),
        mergeMap(({ key }) =>
          of(
            startDiscussionSuccess(key!),
            addChatRoomToUser(key!),
            selectChatRoom(key!),
            canCreateDiscussionReset(),
          ),
        ),
        catchError(e => {
          return of(startDiscussionFailed(e.message));
        }),
      );
    }),
  );
export const createDiscussionEpic = combineEpics(onDiscussionReadyCreateEpic);
