/* eslint-disable @typescript-eslint/camelcase */
import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  switchMap,
  filter,
  mergeMap,
  map,
  catchError,
  takeUntil,
  mapTo,
  delay,
  debounceTime,
  tap,
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { loadStripe } from '@stripe/stripe-js';

import { SIGN_OUT } from '../User';
import { getIsBillingEnabled } from '../Server';
import { getStripePublishableKey, getStripeClientId } from '../Environment';
import { startDiscussion } from '../CreateDiscussion';
import { Metrics } from '../../services/metrics';
import { getUserId, getIdToken, getEmail } from '../Auth';

import {
  requestStripeUrlSuccess,
  requestStripeUrlError,
  confirmOauthSuccess,
  confirmOauthError,
  getSavedAccountError,
  getSavedAccountSuccess,
  createBillingCustomerSuccess,
  createBillingCustomerError,
  initStripeDone,
  getSubscriptionSuccess,
  getSubscriptionError,
  getCustomerError,
  getCustomerSuccess,
  cancelSubscriptionSuccess,
  cancelSubscriptionError,
  reactivateSubscriptionError,
  reactivateSubscriptionSuccess,
  updatePaymentDetailsSuccess,
  updatePaymentDetailsError,
  fetchPlansSuccess,
  fetchPlansError,
  canCreateDiscussionSuccess,
  canCreateDiscussionError,
  availableSubscriptionsReceive,
  availableSubscriptionsReceiveError,
  saveAvailableSubscriptionSuccess,
  saveAvailableSubscriptionError,
} from './actions';
import {
  STRIPE_REDIRECT_URL_REQUESTED,
  PAYMENTS_CONFIRM,
  PAYMENTS_SAVED_ACCOUNT,
  PAYMENTS_CREATE_CUSTOMER,
  INIT_STRIPE,
  SUBSCRIPTION_PLAN,
  PAYMENT_CUSTOMER,
  CANCEL_SUBSCRIPTION,
  REACTIVATE_SUBSCRIPTION,
  UPDATE_PAYMENT_DETAILS,
  FETCH_PLANS,
  CAN_CREATE_DISCUSSION,
  AVAILABLE_SUBSCRIPTIONS_SUBSCRIBE,
  SAVE_AVAILABLE_SUBSCRIPTION,
} from './actionTypes';
import {
  getStripeState,
  getCardBillingDetails,
  getPaymentCustomer,
  getAllPlans,
} from './reducer';

import { RootEpic } from '..';

const urlFactory = (state: string, email: string, redirect: string, clientId: string) =>
  `https://connect.stripe.com/oauth/authorize?client_id=${clientId}&state=${state}&scope=read_write&response_type=code&stripe_user[email]=${email}&redirect_uri=${redirect}`;

export const oauthStateEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(STRIPE_REDIRECT_URL_REQUESTED)),
    mergeMap(() =>
      backendService.payments.getOAuthState(getIdToken(state$.value)!).pipe(
        map(state => {
          const url = urlFactory(
            state,
            getEmail(state$.value)!,
            `${window.origin}/billing/oauth`,
            getStripeClientId(state$.value),
          );
          return requestStripeUrlSuccess(url);
        }),
        catchError(e => of(requestStripeUrlError(e))),
      ),
    ),
  );

export const confirmOAuthEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(PAYMENTS_CONFIRM)),
    mergeMap(({ payload: { code, state } }) =>
      backendService.payments
        .confirmRegistration(code, state, getIdToken(state$.value)!)
        .pipe(
          map(confirmOauthSuccess),
          catchError(e => of(confirmOauthError(e))),
        ),
    ),
  );

export const savedAccountEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(PAYMENTS_SAVED_ACCOUNT)),
    switchMap(() =>
      database.payments.getSavedAccount().pipe(
        map(data => getSavedAccountSuccess(data)),
        catchError(e => of(getSavedAccountError(e))),
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
      ),
    ),
  );

export const stripeEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(INIT_STRIPE)),
    switchMap(() =>
      database.payments
        .getSavedAccount()
        .pipe(takeUntil(action$.pipe(filter(isOfType(SIGN_OUT))))),
    ),
    mergeMap(data => {
      const apikey = getStripePublishableKey(state$.value);
      return from(
        loadStripe(apikey, {
          stripeAccount: data?.id,
        }),
      ).pipe(
        map(stripe => initStripeDone(stripe || undefined)),
        catchError(() => of(initStripeDone(undefined))),
      );
    }),
  );

export const subscriptionPlanEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(SUBSCRIPTION_PLAN)),
    switchMap(() =>
      database.payments.getActiveSubscription().pipe(
        map(getSubscriptionSuccess),
        catchError(e => of(getSubscriptionError(e.message))),
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
      ),
    ),
  );

export const paymentCustomerEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(PAYMENT_CUSTOMER)),
    mergeMap(() =>
      backendService.payments
        .getCustomer(getUserId(state$.value)!, getIdToken(state$.value)!)
        .pipe(
          map(getCustomerSuccess),
          catchError(e => of(getCustomerError(e))),
        ),
    ),
  );
export const cancelSubscriptionEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(CANCEL_SUBSCRIPTION)),
    tap(() => Metrics.getLogger().logEvent('cancelSubscription')),
    mergeMap(({ payload: { subscriptionId } }) =>
      backendService.payments
        .cancelSubscription(
          subscriptionId,
          getUserId(state$.value)!,
          getIdToken(state$.value)!,
        )
        .pipe(
          map(cancelSubscriptionSuccess),
          catchError(e => of(cancelSubscriptionError(e))),
        ),
    ),
  );

export const reactivateSubscriptionEpic: RootEpic = (
  action$,
  state$,
  { backendService },
) =>
  action$.pipe(
    filter(isOfType(REACTIVATE_SUBSCRIPTION)),
    tap(() => Metrics.getLogger().logEvent('reactivateSubscription')),
    mergeMap(({ payload: { subscriptionId } }) =>
      backendService.payments
        .reactivateSubscription(
          subscriptionId,
          getUserId(state$.value)!,
          getIdToken(state$.value)!,
        )
        .pipe(
          map(reactivateSubscriptionSuccess),
          catchError(e => of(reactivateSubscriptionError(e))),
        ),
    ),
  );

export const updatePaymentDetailsEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(UPDATE_PAYMENT_DETAILS)),
    mergeMap(({ payload: { paymentMethodId } }) =>
      backendService.payments
        .updatePaymentDetails(
          paymentMethodId,
          getUserId(state$.value)!,
          getIdToken(state$.value)!,
        )
        .pipe(
          map(updatePaymentDetailsSuccess),
          catchError(e => of(updatePaymentDetailsError(e))),
        ),
    ),
  );

export const canCreateDiscussionEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(CAN_CREATE_DISCUSSION)),
    debounceTime(500),
    switchMap(() => {
      if (!getIsBillingEnabled(state$.value))
        return of(canCreateDiscussionSuccess(true), startDiscussion()).pipe(
          delay(100) /* Adds delay because react didnt pick up the state change*/,
        );
      return backendService.payments
        .canCreateDiscussion(getUserId(state$.value)!, getIdToken(state$.value)!)
        .pipe(
          mergeMap(ok => {
            if (ok) return of(canCreateDiscussionSuccess(ok), startDiscussion());
            return of(canCreateDiscussionSuccess(ok));
          }),
          catchError(e => of(canCreateDiscussionError(e))),
        );
    }),
  );

export const saveAvailableSubscriptionEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(SAVE_AVAILABLE_SUBSCRIPTION)),
    mergeMap(({ payload: { data } }) =>
      database.payments.saveAvailableSubscription(data).pipe(
        map(saveAvailableSubscriptionSuccess),
        catchError(e => of(saveAvailableSubscriptionError(e))),
      ),
    ),
  );

export const allAvailableSubscriptionsEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(AVAILABLE_SUBSCRIPTIONS_SUBSCRIBE)),
    switchMap(() =>
      database.payments.listAllAvailableSubscriptions().pipe(
        map(availableSubscriptionsReceive),
        catchError(e => of(availableSubscriptionsReceiveError(e))),
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
      ),
    ),
  );

export const allPlansEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(FETCH_PLANS)),
    mergeMap(() => {
      const cachedPlans = getAllPlans(state$.value);
      if (cachedPlans.plans) return of(fetchPlansSuccess(cachedPlans.plans));
      return backendService.payments
        .availableSubscriptions(getIdToken(state$.value)!)
        .pipe(
          map(fetchPlansSuccess),
          catchError(e => of(fetchPlansError(e))),
        );
    }),
  );

export const createCustomerAndSubscriptionEpic: RootEpic = (
  action$,
  state$,
  { backendService },
) =>
  action$.pipe(
    filter(isOfType(PAYMENTS_CREATE_CUSTOMER)),
    switchMap(({ payload: { paymentMethodId, planId, card } }) =>
      of(getIdToken(state$.value)!).pipe(
        mergeMap(token => {
          if (getPaymentCustomer(state$.value).customer?.subscriptions.data.length === 0)
            return backendService.payments
              .updatePaymentDetails(paymentMethodId, getUserId(state$.value)!, token)
              .pipe(mapTo(token));
          return backendService.payments
            .createCustomer(
              paymentMethodId,
              token,
              getCardBillingDetails(state$.value).name,
              getCardBillingDetails(state$.value).email,
            )
            .pipe(mapTo(token));
        }),
        mergeMap(token =>
          backendService.payments.createSubscription(
            planId,
            getUserId(state$.value)!,
            token,
          ),
        ),
        mergeMap(subscription => {
          const { latest_invoice } = subscription;
          const { payment_intent } = latest_invoice;
          if (!payment_intent) {
            Metrics.getLogger().logEvent('purchaseSuccess');
            return of(createBillingCustomerSuccess(true));
          }
          const { client_secret, status } = payment_intent;
          if (status !== 'requires_action') {
            Metrics.getLogger().logEvent('purchaseSuccess');
            return of(createBillingCustomerSuccess(true));
          }

          const stripe = getStripeState(state$.value).stripe;
          if (!stripe) return of(createBillingCustomerError('No stripe initialized'));
          const billingDetails = getCardBillingDetails(state$.value);
          return from(
            stripe.confirmCardPayment(client_secret, {
              payment_method: {
                card,
                billing_details: {
                  name: billingDetails.name,
                  email: billingDetails.email,
                },
              },
              setup_future_usage: 'off_session',
            }),
          ).pipe(
            map(result => {
              if (result.error) {
                Metrics.getLogger().logEvent('purchaseError', {
                  code: result.error.code,
                });
                return createBillingCustomerError(result.error.message || '');
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc)
              } else {
                // Show a success message to your customer
                Metrics.getLogger().logEvent('purchaseSuccess');
                return createBillingCustomerSuccess(true);
              }
            }),
          );
        }),
        catchError(() => of(createBillingCustomerError('Unknown error occurred'))),
      ),
    ),
  );
export const paymentsRootEpic = combineEpics(
  oauthStateEpic,
  confirmOAuthEpic,
  savedAccountEpic,
  createCustomerAndSubscriptionEpic,
  stripeEpic,
  subscriptionPlanEpic,
  paymentCustomerEpic,
  cancelSubscriptionEpic,
  reactivateSubscriptionEpic,
  updatePaymentDetailsEpic,
  saveAvailableSubscriptionEpic,
  allAvailableSubscriptionsEpic,
  allPlansEpic,
  canCreateDiscussionEpic,
);
