import mixpanel, { Dict } from 'mixpanel-browser';
import firebase from 'firebase';

import 'firebase/analytics';
import { Claims } from '../modules';
import { isFlagEnabled } from '../config/featureFlags';

const getVersion = () => process.env.REACT_APP_VERSION || '-1';

let globalProperties: Dict = {};

export class Metrics {
  static instance: Metrics;
  static analytics: firebase.analytics.Analytics;

  constructor() {
    if (Metrics.instance) {
      return Metrics.instance;
    }
    Metrics.instance = this;
    return this;
  }

  static getLogger() {
    if (Metrics.analytics) {
      const { logEvent } = Metrics.analytics;
      return {
        logEvent: (event: string, properties?: Dict) => {
          return logEvent(event, { ...globalProperties, ...properties });
        },
      };
    }
    if (!mixpanel) throw new Error('Not initialized properly');
    return {
      logEvent: (event: string, properties?: Dict) => {
        return mixpanel.track(`APP.${event}`, { ...globalProperties, ...properties });
      },
    };
  }

  static init(token: string, tenantId: string) {
    globalProperties = { tenantId, version: getVersion() };
    if (isFlagEnabled('googleAnalytics', tenantId)) {
      Metrics.analytics = firebase.analytics();
    } else {
      mixpanel.init(token, {
        // eslint-disable-next-line @typescript-eslint/camelcase
        api_host: 'https://api-eu.mixpanel.com',
        ip: false,
        debug: getVersion() === '-1' ? true : false,
        persistence: 'localStorage',
      });
    }
  }

  static logout(tenantId: string) {
    if (isFlagEnabled('googleAnalytics', tenantId)) return;
    globalProperties = { tenantId, version: getVersion() };
    mixpanel.reset();
  }

  static setUser(tenantId: string, uid?: string, claims?: Claims) {
    if (isFlagEnabled('googleAnalytics', tenantId)) {
      globalProperties.version = getVersion();
      globalProperties.tenantId = tenantId;
      const props: { [id: string]: string | boolean | undefined } = {
        tenantId,
        version: getVersion(),
      };
      if (claims) {
        props.mentor = claims.mentor;
        props.tenantAdmin = claims.tenantAdmin;
        globalProperties.mentor = claims.mentor;
        globalProperties.tenantAdmin = claims.tenantAdmin;
      }
      Metrics.analytics.setUserProperties(props, { global: true });
    } else {
      uid && mixpanel.identify(uid);
      globalProperties.version = getVersion();
      globalProperties.tenantId = tenantId;

      if (claims) {
        const { mentor, tenantAdmin } = claims;
        mixpanel.people.set({
          tenantId,
          mentor,
          tenantAdmin,
        });
        globalProperties.mentor = mentor;
        globalProperties.tenantAdmin = tenantAdmin;
      }
    }
  }
}
