import i18next from 'i18next';
import { createSelector } from 'reselect';

import { ChatRoomEntity, Message, ChatRoomExtraInfoEntity } from '../../services/db';
import { NamesByIdsResponse } from '../../services/backendService';
import { AppState } from '../rootReducer';
import {
  ById,
  RECEIVE_COMPLETED_CHATROOMS,
  RECEIVE_INCOMPLETE_CHATROOMS,
} from '../Admin';
import theme from '../../theme';
import { randomIntFromInterval } from '../../utils/randomUtils';
import { DiscussionLiveInfo, MeteredDiscussion } from '../../services/database/types';
import { getIsMentor } from '../Auth';

import {
  CHATROOMS_RECEIVED,
  GET_NAMES_BY_ID_SUCCESS,
  CHATROOM_UPDATED,
  CHATROOM_SELECTED,
  DESELECT_CHATROOM,
  SELECT_TAB,
  CHATROOM_EXTRA_INFO_UPDATED,
  RECEIVE_DISCUSSION_LIVE_INFO,
  RECEIVE_TAGS,
  METERED_DISCUSSION_RECEIVED,
} from './actionTypes';

import { RootAction } from '..';

export interface ChatRoomState {
  chatRooms: { [id: string]: ChatRoomEntity };
  namesById: NamesByIdsResponse;
  avatarColorById: ById<string>;
  creatingChatRoomData: {
    isCreating: boolean;
    chatRoomId: string;
  };
  selectedChatRoom?: ChatRoomEntity;
  chatRoomExtraInfo?: ChatRoomExtraInfoEntity;
  activeTab: 0 | 1;
  liveInfoById: ById<DiscussionLiveInfo>;
  tagsById: ById<string[]>;
  meteredDiscussions: ById<MeteredDiscussion>;
}

const initialState: ChatRoomState = {
  chatRooms: {},
  namesById: {},
  avatarColorById: {},
  creatingChatRoomData: {
    isCreating: false,
    chatRoomId: '',
  },
  activeTab: 0,
  liveInfoById: {},
  tagsById: {},
  meteredDiscussions: {},
};

export const chatRoomReducer = (
  state = initialState,
  action: RootAction,
): ChatRoomState => {
  switch (action.type) {
    case RECEIVE_COMPLETED_CHATROOMS:
    case CHATROOMS_RECEIVED: {
      const chatRooms = action.payload.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: any, cur: any) => ({ ...acc, [cur.id]: cur }),
        {},
      );
      return {
        ...state,
        chatRooms,
      };
    }
    // this actions comes from the admin view.
    case RECEIVE_INCOMPLETE_CHATROOMS: {
      const chatRooms = action.payload.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: any, cur: any) => ({ ...acc, [cur.id]: cur }),
        {},
      );
      return {
        ...state,
        chatRooms: {
          ...state.chatRooms,
          ...chatRooms,
        },
      };
    }
    case GET_NAMES_BY_ID_SUCCESS: {
      const validColors = theme.colors.avatar;
      const colors = Object.keys(action.payload).reduce<{
        [id: string]: string;
      }>(
        (acc, cur) => ({
          ...acc,
          [cur]: validColors[randomIntFromInterval(0, validColors.length) - 1],
        }),
        {},
      );
      return {
        ...state,
        namesById: { ...state.namesById, ...action.payload },
        avatarColorById: { ...state.avatarColorById, ...colors },
      };
    }
    case CHATROOM_UPDATED: {
      const updatedId = action.payload.id;
      return {
        ...state,
        chatRooms: { ...state.chatRooms, [updatedId!]: action.payload },
        selectedChatRoom:
          state.selectedChatRoom && state.selectedChatRoom.id === updatedId
            ? action.payload
            : state.selectedChatRoom,
      };
    }
    case CHATROOM_EXTRA_INFO_UPDATED:
      return {
        ...state,
        chatRoomExtraInfo: action.payload,
      };
    case CHATROOM_SELECTED:
      return {
        ...state,
        selectedChatRoom: state.chatRooms[action.payload.chatRoomId],
      };
    case DESELECT_CHATROOM:
      return {
        ...state,
        selectedChatRoom: undefined,
      };
    case SELECT_TAB:
      return {
        ...state,
        activeTab: action.payload.tabId,
      };
    case RECEIVE_DISCUSSION_LIVE_INFO:
      return {
        ...state,
        liveInfoById: {
          ...state.liveInfoById,
          [action.payload.chatRoomId]: action.payload.data,
        },
      };
    case RECEIVE_TAGS:
      return {
        ...state,
        tagsById: {
          ...state.tagsById,
          ...action.payload.tags,
        },
      };
    case METERED_DISCUSSION_RECEIVED:
      return {
        ...state,
        meteredDiscussions: {
          ...state.meteredDiscussions,
          [action.payload.chatRoomId]: action.payload.data,
        },
      };
    default:
      return state;
  }
};

// selectors
export const getChatRoomById = (state: AppState, id?: string) => {
  if (!id) return undefined;
  return state.chatRoom.chatRooms[id];
};

export const getActiveTabId = (state: AppState) => state.chatRoom.activeTab;

const chatroomsSelector = (state: AppState) => state.chatRoom.chatRooms;

const completedChatRoomsSelector = createSelector(chatroomsSelector, chatRooms =>
  Object.values(chatRooms).filter(c => c.isComplete),
);

export const getCompletedChatRooms = createSelector(
  completedChatRoomsSelector,
  chatrooms => chatrooms.sort((a, b) => b.completedAt! - a.completedAt!),
);

export const getActiveChatRooms = createSelector(chatroomsSelector, chatRooms =>
  Object.values(chatRooms)
    .filter(c => !c.isComplete)
    .sort((a, b) => b.createdAt! - a.createdAt!),
);

export const getAllChatRooms = createSelector(chatroomsSelector, c => Object.values(c));
export const getCreatingChatRoomStatus = (state: AppState) =>
  state.chatRoom.creatingChatRoomData;

export const selectNamesById = (state: AppState) => state.chatRoom.namesById;
export const namesByIdSelector = selectNamesById;

export const getNameById = (namesById: NamesByIdsResponse, id?: string) =>
  (namesById[id || ''] && namesById[id || ''].name) || '-';

const colorsByIdSelector = (state: AppState) => state.chatRoom.avatarColorById;

const selectColorById = (colors: ById<string>, id?: string) => {
  if (!id) return undefined;
  return colors[id];
};

export const getSelectedChatRoomMessages = (state: AppState) =>
  state.chatRoom.selectedChatRoom && state.chatRoom.selectedChatRoom.messages;

const selectedChatRoomSelector = (state: AppState) => state.chatRoom.selectedChatRoom;

export const isChatRoomCompleted = (state: AppState, chatRoomId?: string) => {
  if (!chatRoomId) return false;
  const chatRoom = getChatRoomById(state, chatRoomId);
  if (!chatRoom) return false;
  return chatRoom.isComplete;
};

const tagsSelector = (state: AppState) => state.chatRoom.tagsById;
const getTagsById = (tags: ById<string[]>, chatRoomId: string) => tags[chatRoomId] || [];

export const getAdverserialMessageTimestamp = (state: AppState) => {
  let TimeStampUid: string | undefined;
  if (getIsMentor(state)) {
    TimeStampUid = state.chatRoom.selectedChatRoom?.userId;
  } else {
    TimeStampUid = state.chatRoom.selectedChatRoom?.mentorId;
  }
  if (TimeStampUid) {
    const messageTimestamps = state.chatRoom.chatRoomExtraInfo;
    if (messageTimestamps) {
      return messageTimestamps[TimeStampUid];
    }
  }
  return undefined;
};

const getLastMessage = (chatRoom: ChatRoomEntity) => {
  if (!chatRoom.messages) return undefined;
  const messages = Object.values(chatRoom.messages);
  const lastMessage = messages[messages.length - 1];
  return lastMessage;
};

const getFirstMessage = (chatRoom: ChatRoomEntity) => {
  if (!chatRoom.messages) return undefined;
  const messages = Object.values(chatRoom.messages);
  const firstMessage = messages[0];
  return firstMessage;
};

const getLastMessageText = (uid: string, message?: Message) => {
  if (!message) return '';
  const prefix = message.createdBy === uid ? `${i18next.t('You')}:` : '';
  const textMessage = message.imageId ? i18next.t('sent a photo') : message.text;
  return `${prefix} ${textMessage}`;
};

export const getLiveInfoByDiscussionId = (state: AppState, discussionId?: string) => {
  if (!discussionId) return undefined;
  return state.chatRoom.liveInfoById[discussionId];
};
export const getMeteredDiscussionById = (state: AppState, discussionId?: string) => {
  if (!discussionId) return undefined;
  return state.chatRoom.meteredDiscussions[discussionId];
};

export type UIDiscussion = {
  id: string;
  name?: string;
  mentorId?: string;
  avatarColor?: string;
};
export type ActiveChatRoom = UIDiscussion & {
  initialMessage?: Message;
  latestMessage: string;
};

export type FinishedChatRoom = UIDiscussion & {
  message: string;
  tags: string[];
  completedAt?: number;
};

const newToFinished = (
  chatRoom: ChatRoomEntity,
  isMentor: boolean,
  tags: ById<string[]>,
  namesById: NamesByIdsResponse,
  colorsById: ById<string>,
) => ({
  id: chatRoom.id!,
  avatarColor: selectColorById(
    colorsById,
    isMentor ? chatRoom.userId : chatRoom.mentorId,
  ),
  name: chatRoom.mentorId
    ? getNameById(namesById, isMentor ? chatRoom.userId : chatRoom.mentorId)
    : undefined,
  message: getLastMessageText(chatRoom.userId, getLastMessage(chatRoom)),
  tags: getTagsById(tags, chatRoom.id!),
  completedAt: chatRoom.completedAt,
});

const newToActive = (
  chatRoom: ChatRoomEntity,
  isMentor: boolean,
  namesById: NamesByIdsResponse,
  colorsById: ById<string>,
) => ({
  id: chatRoom.id!,
  avatarColor: selectColorById(
    colorsById,
    isMentor ? chatRoom.userId : chatRoom.mentorId,
  ),
  name: chatRoom.mentorId
    ? getNameById(namesById, isMentor ? chatRoom.userId : chatRoom.mentorId)
    : undefined,
  initialMessage: getFirstMessage(chatRoom),
  latestMessage: getLastMessageText(chatRoom.userId, getLastMessage(chatRoom)),
  mentorId: chatRoom.mentorId,
});

export const getSelectedChatRoom = createSelector(
  selectedChatRoomSelector,
  state => getIsMentor(state),
  namesByIdSelector,
  colorsByIdSelector,
  (chatroom, isMentor, namesById, colorsById) =>
    chatroom && newToActive(chatroom, !!isMentor, namesById, colorsById),
);
export const getActiveChatRoomsStripped = createSelector(
  getActiveChatRooms,
  state => getIsMentor(state),
  namesByIdSelector,
  colorsByIdSelector,
  (chatrooms, isMentor, namesById, colorsById) =>
    chatrooms.map(c => newToActive(c, !!isMentor, namesById, colorsById)),
);
export const getCompletedChatRoomStripped = createSelector(
  getCompletedChatRooms,
  state => getIsMentor(state),
  tagsSelector,
  namesByIdSelector,
  colorsByIdSelector,
  (chatrooms, isMentor, tagsById, namesById, colorsById) =>
    chatrooms.map(c => newToFinished(c, !!isMentor, tagsById, namesById, colorsById)),
);

export const getOtherParticipantIdFromDiscussion = (
  state: AppState,
  isMentor: boolean,
  chatRoomId: string,
) => {
  const discussion = getChatRoomById(state, chatRoomId);
  if (!discussion) return undefined;
  return isMentor ? discussion.userId : discussion.mentorId;
};
