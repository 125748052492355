import React, { useEffect } from 'react';
import { Modal } from 'antd';

import { useTypedSelector } from '../hooks';
import { getIsSignedIn } from '../modules';

const FirstTimeLoginProvider = ({ children }: { children: React.ReactChild }) => {
  const isSignedIn = useTypedSelector(getIsSignedIn);

  useEffect(() => {
    const secondTimeAad = localStorage.getItem('@snapmentor/secondTimeAad');
    if (isSignedIn && secondTimeAad === null) {
      localStorage.setItem('@snapmentor/secondTimeAad', 'seen');

      Modal.info({
        title: 'Important notice',
        content: (
          <div>
            <p>
              Snapmentor values personal information immensely, and making sure your data
              is handled appropriately in a secure way is very important for us.{' '}
            </p>
            <p>
              The European Higher Court of Justice struck in July down the legal ground
              for which European enterprises can store personal data in the US, and we had
              to move quickly in order to protect your data.
            </p>
            <p>
              That is why we had to migrate and change the sign in experience of
              snapmentor. It is new, but we hope you like it.
            </p>
            <p>
              For existing users we unfortunately had to enforce you to register a new
              profile to properly protect your old data. All the old personal data will be
              completely deleted before Oktober 1st.
            </p>
          </div>
        ),
      });
    }
  }, [isSignedIn]);

  return <>{children}</>;
};

export default FirstTimeLoginProvider;
