import { from, Subject, forkJoin } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import firebase from 'firebase/app';
import 'firebase/database';
import * as Sentry from '@sentry/browser';
import { objectVal } from 'rxfire/database';

import { ById, Topic, QuestionTypesByTopic, NotificationTemplate } from '../modules';
import { notUndefined } from '../utils/typeUtils';

//PATHS
const USER_PATH = 'users';
const MENTOR_PATH = 'mentors';
const CHAT_ROOM_PATH = 'chatrooms';
const CHAT_ROOM_EXTRA_INFO_PATH = 'chatRoomExtraInfo';
const MESSAGES_PATH = 'messages';
const GENERAL_REVIEW_PATH = 'generalReviews';

export class DB {
  public db: firebase.database.Database;
  tenantId: string;
  timeStamp = firebase.database.ServerValue.TIMESTAMP;

  constructor(tenantId: string) {
    this.db = firebase.database();
    this.tenantId = tenantId;
  }

  getTenantId = async (): Promise<string> => {
    return this.tenantId;
  };

  private rootTenantPath = (tenantId: string) => `tenants/${tenantId}`;

  rootRef = async () => {
    const tenantId = await this.getTenantId();
    return this.db.ref(this.rootTenantPath(tenantId));
  };

  private addRootEntity = async <T>(path: string, object: T, id?: string) => {
    try {
      const rootRef = await this.rootRef();
      const key = id || rootRef.child(path).push().key;

      const updates: { [str: string]: T } = {};
      updates[`/${path}/${key}`] = { id: key!, ...object };
      return { key, updates: await rootRef.update(updates) };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {};
    }
  };

  public addChatRoom = (chatRoom: ChatRoomEntity, id?: string) => {
    return this.addRootEntity<ChatRoomEntity>(CHAT_ROOM_PATH, chatRoom, id);
  };

  public fetchQuestionTemplateById = async (templateId: string) => {
    const rootRef = await this.rootRef();

    const snap = await rootRef
      .child(`/server/notifications/templates/${templateId}`)
      .once('value');
    return snap.val() as NotificationTemplate;
  };

  public createChatRoomExtraInfo = async (key: string, uid: string) => {
    this.setSeenMessageTimeStamp(key, uid);
    return { key };
  };

  public createChatRoomId = async () => {
    const rootRef = await this.rootRef();
    const key = rootRef.child(CHAT_ROOM_PATH).push().key;
    return key;
  };

  public addMentorToChatRoom = async (chatRoom: ChatRoomEntity) => {
    const updates: { [str: string]: ChatRoomEntity | string } = {};
    updates[`${CHAT_ROOM_PATH}/${chatRoom.id}`] = chatRoom;
    updates[
      `${MENTOR_PATH}/${chatRoom.mentorId}/chatRooms/${chatRoom.id}`
    ] = chatRoom.id!;
    const rootRef = await this.rootRef();
    return rootRef.update(updates);
  };

  public addChatRoomToUser = async (chatRoomId: string, userId: string) => {
    const updates: { [str: string]: string } = {};

    updates[`${USER_PATH}/${userId}/chatRooms/${chatRoomId}`] = chatRoomId;
    const rootRef = await this.rootRef();
    return rootRef.update(updates);
  };

  public setChatRoomCompleted = async (chatRoomId: string, completedBy: string) => {
    const chatRoom = await this.getEntityOnce<ChatRoomEntity>(chatRoomId, CHAT_ROOM_PATH);
    const updates: { [str: string]: ChatRoomEntity } = {};
    updates[`${CHAT_ROOM_PATH}/${chatRoomId}`] = {
      ...chatRoom,
      isComplete: true,
      completedBy,
      completedAt: new Date().getTime(),
    };
    const rootRef = await this.rootRef();
    return rootRef.update(updates);
  };

  public sendMessage = async (message: Message, chatRoomId: string) => {
    const rootRef = await this.rootRef();
    if (!(message.imageId || message.text))
      throw Error('Must specify either an image or a text in the message');
    // TODO ADD METRIC
    const path = `${CHAT_ROOM_PATH}/${chatRoomId}/${MESSAGES_PATH}`;
    const key = rootRef
      .child(CHAT_ROOM_PATH)
      .child(chatRoomId)
      .child(MESSAGES_PATH)
      .push().key;
    const updates: { [str: string]: Message } = {};
    updates[`${path}/${key}`] = {
      ...message,
      id: key!,
      timeStamp: new Date().getTime(),
    };
    return rootRef.update(updates);
  };
  public setSeenMessageTimeStamp = async (chatRoomId: string, uid: string) => {
    const rootRef = await this.rootRef();
    if (uid) {
      return rootRef
        .child(`${CHAT_ROOM_EXTRA_INFO_PATH}/${chatRoomId}/${uid}`)
        .set(this.timeStamp);
    }
    return rootRef;
  };

  //REFS
  public getChatRoomRef = async (chatRoomId: string) => {
    const rootRef = await this.rootRef();
    return rootRef.child(`${CHAT_ROOM_PATH}/${chatRoomId}`);
  };
  public getChatRoomExtraInfoRef = async (chatRoomId: string) => {
    const rootRef = await this.rootRef();
    return rootRef.child(`${CHAT_ROOM_EXTRA_INFO_PATH}/${chatRoomId}`);
  };

  public getChatRoomIdsByMentorIdRef = async (mentorId: string) => {
    const rootRef = await this.rootRef();
    return rootRef
      .child(MENTOR_PATH)
      .child(mentorId)
      .child('chatRooms');
  };

  public getChatRoomIdsByUserIdRef = async (userId: string) => {
    const rootRef = await this.rootRef();
    return rootRef
      .child(USER_PATH)
      .child(userId)
      .child('chatRooms');
  };

  private getEntityOnce = async <T>(id: string, path: string): Promise<T> => {
    const rootRef = await this.rootRef();
    const snapshot = await rootRef.child(`${path}/${id}`).once('value');
    return snapshot.val();
  };

  public getUser = (userId: string): Promise<User> =>
    this.getEntityOnce(userId, USER_PATH);
  public getMentor = (mentorId: string): Promise<Mentor> =>
    this.getEntityOnce(mentorId, MENTOR_PATH);

  public getChatRoom = async (
    chatRoomId: string,
    uid: string,
  ): Promise<ChatRoomEntity | undefined> => {
    try {
      const entity = await this.getEntityOnce<ChatRoomEntity>(chatRoomId, CHAT_ROOM_PATH);
      return entity;
    } catch (error) {
      Sentry.captureEvent({
        message: `[getChatRoom exception]: ${error.message}`,
        level: Sentry.Severity.Info,
        tags: {
          uid,
        },
        stacktrace: error.stack,
      });
      return undefined;
    }
  };

  public getCompletedChatRooms = () =>
    from(this.rootRef()).pipe(
      mergeMap(ref =>
        objectVal<{ [id: string]: ChatRoomEntity }>(
          ref
            .child(CHAT_ROOM_PATH)
            .orderByChild('isComplete')
            .equalTo(true),
        ).pipe(
          map(rooms => Object.values(rooms).filter(room => room.claimedAt !== undefined)),
        ),
      ),
    );
  public getUnClaimedChatRooms = () =>
    from(this.rootRef()).pipe(
      mergeMap(ref =>
        objectVal<{ [id: string]: ChatRoomEntity }>(
          ref
            .child(CHAT_ROOM_PATH)
            .orderByChild('claimedAt')
            .equalTo(null),
        ),
      ),
    );

  public getInCompleteChatRooms = () => {
    return from(this.rootRef()).pipe(
      mergeMap(ref =>
        objectVal<ChatRoomEntity[]>(
          ref
            .child(CHAT_ROOM_PATH)
            .orderByChild('isComplete')
            .equalTo(false),
        ),
      ),
    );
  };

  public subscribeToRef = <T>(ref: firebase.database.Reference) => {
    const sub = new Subject<T | null>();
    ref.on('value', snapShot => {
      const value: T | null = snapShot.val();
      sub.next(value);
    });
    return sub.asObservable();
  };

  public subscribeToChatRoom = (chatRoomId: string) => {
    return from(this.getChatRoomRef(chatRoomId)).pipe(
      mergeMap(ref => this.subscribeToRef<ChatRoomEntity>(ref)),
    );
  };

  public subscribeToChatRoomExtraInfo = (chatRoomId: string) => {
    return from(this.getChatRoomExtraInfoRef(chatRoomId)).pipe(
      mergeMap(ref => this.subscribeToRef<ChatRoomExtraInfoEntity>(ref)),
    );
  };

  public chatRoomsByMentorId = (mentorId: string) => {
    return from(this.getChatRoomIdsByMentorIdRef(mentorId)).pipe(
      mergeMap(ref => this.subscribeToRef<{ [id: string]: string }>(ref)),
      map(val => Object.values(val || {})),
      mergeMap(ids => forkJoin(ids.reverse().map(id => this.getChatRoom(id, mentorId)))),
      map(chatRooms => chatRooms.filter(notUndefined)),
    );
  };

  public chatRoomsByUserId = (userId: string) => {
    return from(this.getChatRoomIdsByUserIdRef(userId)).pipe(
      mergeMap(ref => this.subscribeToRef<{ [id: string]: string }>(ref)),
      map(val => Object.values(val || {})),
      mergeMap(ids => forkJoin(ids.reverse().map(id => this.getChatRoom(id, userId)))),
      map(chatRooms => chatRooms.filter(notUndefined)),
    );
  };

  public addGeneralReview = async (review: GeneralReview) => {
    const rootRef = await this.rootRef();

    const ref = rootRef
      .child(GENERAL_REVIEW_PATH)
      .child(review.userId)
      .push();
    return ref.set(review);
  };

  matchMakingRef = (id: string) =>
    this.rootRef().then(ref => ref.child(`server/matchmaking/${id}`));

  retrieveMatchmaking = async (id: string): Promise<MatchmakingElement> => {
    const ref = await this.matchMakingRef(id);
    const snap = await ref.once('value');
    return snap.val();
  };

  topicRef = async () => this.rootRef().then(ref => ref.child('server/topics'));
  productsRef = async () => this.rootRef().then(ref => ref.child('server/products'));
  questionTypesRef = async () =>
    this.rootRef().then(ref => ref.child('server/questionTypes'));
  questionTypesByTopicRef = async () =>
    this.rootRef().then(ref => ref.child('server/questionTypesByTopic'));

  updateTopics = async (topics: ById<Topic>) => {
    const ref = await this.topicRef();
    await ref.set(topics);

    const questionsByTopicRef = await this.questionTypesByTopicRef();
    const questions = ['startupHelp', 'stepByStep', 'review', 'other'];
    const questionTypesByTopic: QuestionTypesByTopic = Object.values(topics).reduce(
      (questionTypes, topic) => ({
        ...questionTypes,
        [topic.id]: questions,
      }),
      {},
    );
    await questionsByTopicRef.set(questionTypesByTopic);
  };
  pendingReviewsRef = (uid: string) =>
    this.rootRef().then(ref =>
      ref
        .child('reviewQueue')
        .child(uid)
        .child('pending'),
    );
  lastSeenReviewsRef = (uid: string) =>
    this.rootRef().then(ref =>
      ref
        .child('reviewQueue')
        .child(uid)
        .child('lastReviewed'),
    );

  pendingReviewDelete = (uid: string, id: string) =>
    this.rootRef().then(ref =>
      ref
        .child('reviewQueue')
        .child(uid)
        .child('pending')
        .child(id)
        .remove(),
    );
}

export type PendingReview = {
  id: string;
  createdAt: number;
  templateId: string;
  metadata?: {
    mentorId: string;
    chatRoomId: string;
  };
};

export interface DeletedUser {
  uid: string;
  timestamp: number;
}

export type Consent = {
  id: string;
  title: string;
  description: string;
};
export interface Consents {
  [id: string]: Consent;
}

export type UserConsent = {
  timestamp?: number;
  uid?: string;
  hasConsented: boolean;
  consentId: string;
};

export interface UserConsents {
  [id: string]: UserConsent;
}

export interface User {
  id?: string;
  name: string;
}

export interface UserType {
  uid: string;
  id: string;
  instanceIds?: ById<string>;
  isAvailable?: boolean;
  mentorPreferences?: MentorPreferences;
  nickName?: string;
  lastSeenInfoBanner?: Date;
}

export interface Mentor {
  id?: string;
  name?: string;
  chatRooms?: { [chatRoomId: string]: string };
  userType?: UserType;
}

export interface ChatRoomEntity {
  id?: string;
  userId: string;
  mentorId?: string;
  isComplete: boolean;
  completedBy?: string;
  claimedAt?: number;
  createdAt?: number;
  completedAt?: number;
  messages?: ById<Message>;
  // Todo must ensure that this exists somewhere in the future
  additionalInfo?: AdditionalInfo;
}

export type AdditionalInfo = {
  product: string;
  topic: string[];
  questionType: string;
};
export interface ChatRoomExtraInfoEntity {
  [uid: string]: number;
}

export type MentorPreferences = {
  topics: {
    [topicName: string]: ById<string>;
  };
};

export interface Message {
  id?: string;
  text?: string;
  imageId?: string;
  createdBy: string;
  timeStamp?: number;
}

export interface QuestionnaireForm {
  id?: string;
  0?: 'yes' | 'no';
  1?: number;
  2?: 'yes' | 'no';
  3?: string;
  name: string;
}

export interface GeneralReview {
  userId: string;
  timestamp: number;
  stars: number;
  comment?: string;
}

export type MentorReviewUI = {
  mentorId: string;
  stars: number;
  comment?: string;
  chatRoomId?: string;
};
export interface ReviewUI {
  stars: number;
  comment?: string;
  chatRoomId?: string;
}

type MentorReview = GeneralReview & {
  chatRoomId?: string;
  mentorId: string;
};
export interface MentorReviewDB {
  [mentorId: string]: {
    [chatRoomId: string]: MentorReview;
  };
}

export type Transactions = {
  [id: string]: Transaction;
};

type Transaction = {
  creditType: 'sevenMinutes' | 'thirtyMinutes';
  createdAt: number;
  amountUsed: number;
};

export type UserFeedback = {
  userFeedback: {
    [uid: string]: {
      byTemplateId: {
        [templateId: string]: {
          [responseId: string]: UserFeedbackResponse;
        };
      };
    };
  };
};

export type UserFeedbackResponse = {
  id: string;
  templateId: string;
  createdBy: string;
  createdAt: number;
  value: number | string;
  comment?: string;
  answerId?: string;
  metadata?: {
    [key: string]: string;
  };
};

export type MatchmakingElement = {
  createdAt: number;
  updatedAt: number;
  chatRoomId: string;
  mentors: { [id: string]: string };
  isClaimed: boolean;
  claimedBy?: string;
  id: string;
  chatRoomData?: ChatRoomEntity;
};
