import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Modal } from 'antd';

import { ChatRoomHeader } from '../../_molecules/ChatRoomHeader';
import Background from '../../../assets/images/background_grey.png';
import { MessageController } from '../../_molecules/MessageController/MessageController';
import { Messages } from '../../_molecules/Messages';
import { useTypedSelector } from '../../../hooks';
import {
  getSelectedChatRoomMessages,
  getUserId,
  getSelectedChatRoom,
  isChatRoomCompleted,
  setUserMessageTimeStamp,
  getAdverserialMessageTimestamp,
  getLiveInfoByDiscussionId,
} from '../../../modules';
import { PreviewImage } from '../PreviewImage';

import { CompleteActionPanel } from './CompleteActionPanel';

type Props = {};
export default () => {
  const dispatch = useDispatch();
  const selectedChatRoom = useTypedSelector(getSelectedChatRoom);
  const messages = useTypedSelector(getSelectedChatRoomMessages);
  const adverserialSeenMessageTimestamp = useTypedSelector(
    getAdverserialMessageTimestamp,
  );
  const isChatRoomComplete = useTypedSelector(state =>
    isChatRoomCompleted(state, selectedChatRoom && selectedChatRoom.id),
  );
  const [fullScreenImage, setFullScreenImage] = useState<string>();
  const handleImageClicked = useCallback((imgString: string) => {
    setFullScreenImage(imgString);
  }, []);
  const uid = useTypedSelector(getUserId);

  const discussionLiveInfo = useTypedSelector(state =>
    getLiveInfoByDiscussionId(state, selectedChatRoom?.id),
  );
  useEffect(() => {
    if (uid && messages && selectedChatRoom) {
      dispatch(setUserMessageTimeStamp(selectedChatRoom.id));
    }
    // eslint-disable-next-line
  }, [messages]);

  return (
    <Container>
      <HeaderContainer>
        {selectedChatRoom && (
          <ChatRoomHeader
            name={selectedChatRoom.name}
            avatarColor={selectedChatRoom.avatarColor}
            chatRoomId={selectedChatRoom.id}
            mentorId={selectedChatRoom.mentorId}
            adverserialSeenMessageTimestamp={adverserialSeenMessageTimestamp}
            isTyping={discussionLiveInfo && discussionLiveInfo.typing}
          />
        )}
      </HeaderContainer>
      <ConversationContainer>
        {/* This fills the empty space */}
        {/* <div style={{ flex: '1 1 auto' }} /> */}
        {messages && uid && selectedChatRoom && (
          <Messages
            messages={messages}
            uid={uid}
            chatRoomId={selectedChatRoom.id}
            mentorName={selectedChatRoom.mentorId ? selectedChatRoom.name : undefined}
            onImageClicked={handleImageClicked}
          />
        )}
      </ConversationContainer>

      <MessageControllerContainer>
        {!isChatRoomComplete && selectedChatRoom && uid && (
          <MessageController uid={uid} chatRoomId={selectedChatRoom.id} />
        )}
        {isChatRoomComplete && <CompleteActionPanel />}
      </MessageControllerContainer>
      <StyledModal
        width="95%"
        visible={fullScreenImage !== undefined}
        onOk={() => setFullScreenImage(undefined)}
        onCancel={() => setFullScreenImage(undefined)}
        footer={null}
        closable={false}
        bodyStyle={{ padding: 0 }}
        centered
      >
        <FullSizeDiv onClick={() => setFullScreenImage(undefined)}>
          {fullScreenImage && <PreviewImage src={fullScreenImage} />}
        </FullSizeDiv>
      </StyledModal>
    </Container>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 25px;
    background: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    box-shadow: none;
  }
`;

const FullSizeDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 100%;
  height: 100%;
  background-image: url(${Background});
  background-color: #f9f3eb;
`;

const HeaderContainer = styled.div`
  display: flex;
`;

const ConversationContainer = styled.div`
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start unsafe;
`;

const MessageControllerContainer = styled.div`
  flex: 0;
  height: 76px;
`;
