import { createStore, applyMiddleware, Reducer } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import * as Sentry from '@sentry/browser';
import { composeWithDevTools } from 'redux-devtools-extension';
import firebase from 'firebase/app';

import {
  RootAction,
  initEnvironment,
  rootReducer,
  AppState,
  rootEpic,
  loadAuth,
} from '../modules';
import { DB } from '../services/db';
import { config } from '../config/environments.production';
import { BackendService } from '../services/backendService';
import Database from '../services/database';
import { getTenantIdFromSubdomain } from '../utils/navigatorUtils';
import Files from '../services/files';
import Authentication from '../services/authentication';
import Notifications from '../services/notifications';
import { Metrics } from '../services/metrics';

import { createLocationLoggerMiddleware } from './middleware';

// export type AppState = ReturnType<typeof rootReducer>;
export interface AppDependencies {
  db: DB;
  database: Database;
  files: Files;
  auth: Authentication;
  notifications: Notifications;
  backendService: BackendService;
}

export const getConfig = () => {
  const { origin } = window.location;
  let appConfig = config;
  if (
    origin.includes('localhost') ||
    origin.includes('-dev') ||
    origin.includes('snapmentor-1')
  ) {
    const devConfig = require('../config/environments').config;
    // eslint-disable-next-line no-console
    console.log('Connecting to dev');
    appConfig = devConfig;
  }
  return appConfig;
};

export const configureStore = () => {
  const config = getConfig();
  firebase.initializeApp(config.firebase);
  const tenantId = getTenantIdFromSubdomain(window.origin);
  const dbService = new DB(tenantId);
  const database = new Database(tenantId);
  const files = new Files(tenantId);
  const auth = new Authentication(config.azure);
  const notifications = new Notifications(config.cloudMessaging.vapidKey);

  Metrics.init(config.mixpanel.token, tenantId);

  const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    AppState,
    AppDependencies
  >({
    dependencies: {
      db: dbService,
      database,
      files,
      auth,
      notifications,
      backendService: new BackendService(
        config.backend.url,
        config.backend.externalApiUrl,
      ),
    },
  });

  const middlewares = [epicMiddleware, createLocationLoggerMiddleware()];

  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];

  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer as Reducer, composedEnhancers);
  epicMiddleware.run(rootEpic);
  store.dispatch(initEnvironment(config));

  store.dispatch(loadAuth());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (process.env.NODE_ENV !== 'production' && (module as any).hot) {
    // Enable Webpack hot module replacement for reducers
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (module as any).hot.accept('../modules/rootReducer', () => {
      /* eslint-disable global-require */
      const nextReducer = require('../modules/rootReducer').default;
      store.replaceReducer(nextReducer);
    });
  }

  if (
    !window.origin.startsWith('https://localhost') ||
    !window.origin.startsWith('http://localhost')
  ) {
    Sentry.init({
      dsn: config.sentry.dsn,
    });
  }

  return { store, notifications, auth };
};
