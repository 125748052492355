import { isOfType } from 'typesafe-actions';
import {
  filter,
  mergeMap,
  map,
  catchError,
  tap,
  takeUntil,
  switchMap,
  distinctUntilChanged,
} from 'rxjs/operators';
import { of, from, empty } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { getIsMentor } from '../index';
import { SIGN_OUT } from '../User';
import { catchHandler } from '../utils';
import { Metrics } from '../../services/metrics';
import { getIsMeteredDiscussionsEnabled } from '../Server';
import { getUserId, getIdToken } from '../Auth';

import {
  getAllChatRooms,
  selectNamesById,
  getOtherParticipantIdFromDiscussion,
} from './reducer';
import {
  CHATROOMS_REQUESTED,
  GET_NAMES_BY_ID,
  CHATROOMS_RECEIVED,
  ADD_CHATROOM_TO_USER,
  CHATROOM_COMPLETED,
  CHATROOM_SUBSCRIBE,
  CHATROOM_EXTRA_INFO_SUBSCRIBE,
  SEND_MESSAGE,
  UPLOAD_IMAGE,
  SET_USER_MESSAGE_TIMESTAMP,
  CHATROOM_SELECTED,
  SUBSCRIBE_DISCUSSION_LIVE_INFO,
  SET_DISCUSSION_LIVE_INFO,
  START_DISCUSSION_TIMER,
} from './actionTypes';
import {
  chatRoomReceived,
  getNamesFailed,
  getNamesSuccess,
  getNames,
  chatRoomUpdated,
  chatRoomSubscribe,
  chatRoomExtraInfoUpdated,
  receiveDiscussionLiveInfo,
  subscribeDiscussionLiveInfo,
  receiveTags,
  meteredDiscussionReceived,
} from './actions';

import { RootEpic } from '..';

export const chatRoomsEpic: RootEpic = (action$, state$, { db }) =>
  action$.pipe(
    filter(isOfType(CHATROOMS_REQUESTED)),
    map(() => ({
      isMentor: getIsMentor(state$.value),
      uid: getUserId(state$.value),
    })),
    mergeMap(({ isMentor, uid }) =>
      (isMentor ? db.chatRoomsByMentorId(uid!) : db.chatRoomsByUserId(uid!)).pipe(
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
      ),
    ),
    map(chatRooms => chatRoomReceived(chatRooms)),
    catchError(catchHandler),
  );

export const tagsEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(CHATROOMS_RECEIVED)),
    filter(() => getIsMentor(state$.value)),
    map(action => action.payload.map(c => c.id!)),
    switchMap(ids =>
      database.tags
        .fetchByIds(ids)
        .pipe(takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))), map(receiveTags)),
    ),
    catchError(catchHandler),
  );
export const onChatRoomCompletedSubscribeToTags: RootEpic = (
  action$,
  state$,
  { database },
) =>
  action$.pipe(
    filter(isOfType(CHATROOM_COMPLETED)),
    filter(() => getIsMentor(state$.value)),
    switchMap(action =>
      database.tags.get(action.payload).pipe(
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
        map(tags => receiveTags(tags)),
        catchError(catchHandler),
      ),
    ),
  );

export const onAddChatRoomToUserEpic: RootEpic = (action$, state$, { db }) =>
  action$.pipe(
    filter(isOfType(ADD_CHATROOM_TO_USER)),
    mergeMap(action => {
      const uid = getUserId(state$.value);
      return from(db.addChatRoomToUser(action.payload, uid!));
    }),
    mergeMap(() => empty()),
    catchError(catchHandler),
  );

export const setUserMessageTimeStampEpic: RootEpic = (action$, state$, { db }) =>
  action$.pipe(
    filter(isOfType(SET_USER_MESSAGE_TIMESTAMP)),
    mergeMap(action =>
      db.createChatRoomExtraInfo(action.payload.chatRoomId, getUserId(state$.value)!),
    ),
    mergeMap(() => empty()),
  );

export const namesByIdsEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(GET_NAMES_BY_ID)),
    map(action => action.payload),
    mergeMap(payload => {
      const memoryCache = selectNamesById(state$.value);
      if (memoryCache && Object.keys(memoryCache).length > 0)
        return of(getNamesSuccess(memoryCache));
      return backendService.namesByIds(payload, getIdToken(state$.value)!).pipe(
        map(response => getNamesSuccess(response)),
        catchError(() => {
          return of(getNamesFailed());
        }),
      );
    }),
  );

export const onChatRoomsReceived: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isOfType(CHATROOMS_RECEIVED)),
    map(() => ({
      isMentor: getIsMentor(state$.value),
      chatRooms: getAllChatRooms(state$.value),
    })),
    map(({ isMentor, chatRooms }) =>
      getNames(chatRooms.map(c => (isMentor ? c.userId! : c.mentorId!))),
    ),
    catchError(catchHandler),
  );

export const onChatRoomCompleted: RootEpic = (action$, state$, { db }) =>
  action$.pipe(
    filter(isOfType(CHATROOM_COMPLETED)),
    mergeMap(action =>
      from(db.setChatRoomCompleted(action.payload, getUserId(state$.value)!)).pipe(
        tap(() =>
          Metrics.getLogger().logEvent('chatRoomCompleted', {
            chatRoomId: action.payload,
          }),
        ),
      ),
    ),
    mergeMap(() => empty()),
    catchError(catchHandler),
  );

export const onChatRoomReceivedSubscribeToActive: RootEpic = action$ =>
  action$.pipe(
    filter(isOfType(CHATROOMS_RECEIVED)),
    mergeMap(action =>
      from(action.payload.filter(c => !c.isComplete).map(c => chatRoomSubscribe(c.id!))),
    ),
    catchError(catchHandler),
  );

export const subscribeToChatRoom: RootEpic = (action$, _, { db }) =>
  action$.pipe(
    filter(isOfType(CHATROOM_SUBSCRIBE)),
    mergeMap(action =>
      from(db.subscribeToChatRoom(action.payload)).pipe(
        map(chatRoom => chatRoomUpdated(chatRoom!)),
        catchError(() => empty()),
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
      ),
    ),

    catchError(catchHandler),
  );

export const subscribeToChatRoomExtraInfo: RootEpic = (action$, _, { db }) =>
  action$.pipe(
    filter(isOfType(CHATROOM_EXTRA_INFO_SUBSCRIBE)),
    switchMap(action =>
      from(db.subscribeToChatRoomExtraInfo(action.payload)).pipe(
        map(chatRoom => chatRoomExtraInfoUpdated(chatRoom!)),
        catchError(() => empty()),
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
      ),
    ),
    catchError(catchHandler),
  );

export const discussionLiveInfoSubscription: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(SUBSCRIBE_DISCUSSION_LIVE_INFO)),
    switchMap(({ payload: { chatRoomId } }) => {
      const isMentor = getIsMentor(state$.value);
      if (isMentor === undefined) return empty();
      const uid = getOtherParticipantIdFromDiscussion(state$.value, isMentor, chatRoomId);
      if (!uid) return empty();
      return database.discussions.subscribeToLiveInfo(chatRoomId, uid).pipe(
        map(data => receiveDiscussionLiveInfo(chatRoomId, uid, data)),
        catchError(() => empty()),
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
      );
    }),
    catchError(catchHandler),
  );

export const setDiscussionLiveInfoEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(SET_DISCUSSION_LIVE_INFO)),
    distinctUntilChanged(
      (prev, cur) =>
        prev.payload.chatRoomId === cur.payload.chatRoomId &&
        prev.payload.isTyping === cur.payload.isTyping,
    ),
    mergeMap(({ payload: { chatRoomId, isTyping } }) =>
      database.discussions.setLiveInfo(chatRoomId, getUserId(state$.value)!, isTyping),
    ),
    mergeMap(() => empty()),
  );

export const selectedChatRoomEpic: RootEpic = action$ =>
  action$.pipe(
    filter(isOfType(CHATROOM_SELECTED)),
    map(({ payload: { chatRoomId } }) => subscribeDiscussionLiveInfo(chatRoomId)),
  );

export const sendMessageToChatRoom: RootEpic = (action$, _, { db }) =>
  action$.pipe(
    filter(isOfType(SEND_MESSAGE)),
    mergeMap(({ payload: { chatRoomId, message } }) =>
      from(db.sendMessage(message, chatRoomId)).pipe(
        tap(() =>
          Metrics.getLogger().logEvent('chatRoomSendMessage', {
            chatRoomId,
            isText: true,
            isImage: false,
          }),
        ),
        mergeMap(() => empty()),
        catchError(() => empty()),
      ),
    ),
    catchError(catchHandler),
  );

export const startDiscussionTimerEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(START_DISCUSSION_TIMER)),
    mergeMap(({ payload: { chatRoomId } }) =>
      database.discussions
        .startMeteredDiscussions(chatRoomId)
        .pipe(catchError(() => empty())),
    ),
    mergeMap(() => empty()),
    catchError(catchHandler),
  );

export const subscribeMeteredDiscussionTimer: RootEpic = (
  action$,
  state$,
  { database },
) =>
  action$.pipe(
    filter(isOfType(CHATROOM_SUBSCRIBE)),
    filter(() => !!getIsMeteredDiscussionsEnabled(state$.value)),
    mergeMap(action =>
      from(database.discussions.getMeteredDiscussionById(action.payload)).pipe(
        map(data => meteredDiscussionReceived(action.payload, data)),
        catchError(() => {
          return empty();
        }),
      ),
    ),
    catchError(catchHandler),
  );

export const uploadImageEpic: RootEpic = (action$, _, { files, db }) =>
  action$.pipe(
    filter(isOfType(UPLOAD_IMAGE)),
    map(action => ({
      ...action.payload,
      imageId: new Date().getTime().toString(),
    })),
    mergeMap(({ chatRoomId, imgDataUrl, uid, imageId }) =>
      from(files.uploadDataUrlImage(imageId, chatRoomId, imgDataUrl)).pipe(
        mergeMap(() =>
          from(
            db.sendMessage(
              {
                createdBy: uid,
                imageId,
              },
              chatRoomId,
            ),
          ),
        ),
        tap(() =>
          Metrics.getLogger().logEvent('chatRoomSendMessage', {
            chatRoomId,
            isText: false,
            isImage: true,
          }),
        ),
        catchError(catchHandler),
      ),
    ),
    mergeMap(() => empty()),
    catchError(catchHandler),
  );

export const chatRoomRootEpic = combineEpics(
  chatRoomsEpic,
  namesByIdsEpic,
  onChatRoomsReceived,
  onAddChatRoomToUserEpic,
  onChatRoomCompleted,
  setUserMessageTimeStampEpic,
  subscribeToChatRoom,
  subscribeToChatRoomExtraInfo,
  onChatRoomReceivedSubscribeToActive,
  sendMessageToChatRoom,
  uploadImageEpic,
  selectedChatRoomEpic,
  discussionLiveInfoSubscription,
  setDiscussionLiveInfoEpic,
  tagsEpic,
  onChatRoomCompletedSubscribeToTags,
  startDiscussionTimerEpic,
  subscribeMeteredDiscussionTimer,
);
