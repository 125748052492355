import { combineReducers } from 'redux';

import { ApiKey, RoleUser } from '../../services/backendService';
import { Mentor, UserType, ChatRoomEntity } from '../../services/db';
import { AppState } from '../rootReducer';
import { selectServerData, Topic } from '../Server';
import { APIState, DEFAULT_API_STATE } from '../utils';
import {
  ChatRoomMetrics,
  UserRating,
  PopularTag,
  MentorAccessRequest,
  Announcements,
  MeteredDiscussionConfig,
} from '../../services/database/types';

import {
  RECEIVE_USERS,
  RECEIVE_MENTOR,
  RECEIVE_INCOMPLETE_CHATROOMS,
  CONVERT_TO_MENTOR_SUCCESS,
  FETCH_USERS,
  FETCH_MENTOR,
  FETCH_INCOMPLETE_CHATROOMS,
  FETCH_USERTYPES,
  RECEIVE_USERTYPES,
  CONNECT_USERS_AND_USERTYPES,
  CONVERT_TO_MENTOR_FAILURE,
  CONVERT_TO_MENTOR,
  RECEIVE_COMPLETED_CHATROOMS,
  RECEIVE_UNCLAIMED_CHATROOMS,
  RECEIVE_MENTOR_ACCESS_REQUESTS,
  UPLOAD_CUSTOM_LOGO,
  UPLOAD_CUSTOM_LOGO_SUCCESS,
  UPLOAD_CUSTOM_LOGO_ERROR,
  FETCH_REPORTS,
  RECEIVE_REPORTS,
  RECEIVE_RATINGS,
  FETCH_RATINGS,
  RECEIVE_POPULAR_TAGS,
  FETCH_POPULAR_TAGS,
  FETCH_ANNOUNCEMENTS,
  FETCH_ANNOUNCEMENTS_ERROR,
  FETCH_ANNOUNCEMENTS_SUCCESS,
  ADMIN_DELETE_USER_SUCCESS,
  LIST_API_KEYS_SUCCESS,
  LIST_API_KEYS,
  SAVE_METERED_DISCUSSION_CONFIG,
  SAVE_METERED_DISCUSSION_CONFIG_SUCCESS,
  SAVE_METERED_DISCUSSION_CONFIG_ERROR,
  GET_METERED_DISCUSSION_CONFIG,
  GET_METERED_DISCUSSION_CONFIG_SUCCESS,
  GET_METERED_DISCUSSION_CONFIG_ERROR,
} from './actionTypes';

import { RootAction } from '..';

export type ById<T> = {
  [id: string]: T;
};
interface AdminState {
  hasFetched: HasFetched;
  isLoading: AdminLoading;
  users: RoleUser[];
  userTypes: ById<UserType>;
  userAndUserTypes: (RoleUser & { userType: UserType })[];
  userTableList: (RoleUser & { userType?: UserType })[];
  inCompleteChatRoomsCount?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availableMentors: any[];
  selectedMentor: SelectedMentor;
  completedChatRoomsCount?: number;
  unClaimedChatRoomsCount?: number;
  cancelledChatRoomsCount?: number;
  inQueueCount?: number;
  unClaimedChatRooms: ChatRoomEntity[];
}
interface HasFetched {
  users: boolean;
  userTypes: boolean;
}
interface SelectedMentor {
  id?: string;
  mentor?: Mentor;
  userType?: UserType;
}
interface AdminLoading {
  loadingUsers: boolean;
  loadingMentor: boolean;
  loadingUnclaimedChatRooms: boolean;
  convertingMentor: boolean;
}
const initAdminLoading: AdminLoading = {
  loadingUsers: false,
  loadingMentor: false,
  loadingUnclaimedChatRooms: false,
  convertingMentor: false,
};
const initState: AdminState = {
  hasFetched: { users: false, userTypes: false },
  users: [],
  userTableList: [],
  userTypes: {},
  userAndUserTypes: [],
  isLoading: initAdminLoading,
  selectedMentor: {},
  availableMentors: [],
  unClaimedChatRooms: [],
  // mentorAccessRequests: {},
};
const oldAdminReducer = (
  state: AdminState = initState,
  action: RootAction,
): AdminState => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        users: [],
        isLoading: {
          ...state.isLoading,
          loadingUsers: true,
        },
      };
    case FETCH_MENTOR:
      return {
        ...state,
        selectedMentor: {
          id: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          loadingMentor: true,
        },
      };
    case FETCH_INCOMPLETE_CHATROOMS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          loadingUnclaimedChatRooms: true,
        },
      };
    case FETCH_USERTYPES:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          loadingUsers: true,
        },
      };
    case RECEIVE_USERS:
      //prevent overiding of filteredList
      return {
        ...state,
        users: action.payload,
        isLoading: {
          ...state.isLoading,
        },
        hasFetched: {
          ...state.hasFetched,
          users: true,
        },
      };
    case RECEIVE_MENTOR:
      return {
        ...state,
        selectedMentor: {
          ...state.selectedMentor,
          mentor: action.payload,
          userType: state.userTypes[state.selectedMentor.id!],
        },
        isLoading: {
          ...state.isLoading,
          loadingMentor: false,
        },
      };
    case RECEIVE_INCOMPLETE_CHATROOMS: {
      const unAssigned = action.payload.filter(c => c.mentorId === undefined);
      return {
        ...state,
        inCompleteChatRoomsCount: action.payload.length - unAssigned.length,
        inQueueCount: unAssigned.length,
        isLoading: {
          ...state.isLoading,
          loadingUnclaimedChatRooms: false,
        },
      };
    }
    case RECEIVE_USERTYPES:
      return {
        ...state,
        userTypes: action.payload,
        isLoading: {
          ...state.isLoading,
        },
        hasFetched: {
          ...state.hasFetched,
          userTypes: true,
        },
      };
    case CONNECT_USERS_AND_USERTYPES:
      // eslint-disable-next-line no-case-declarations
      const connectedUserAndUsertype = state.users.map(user => {
        return { ...user, userType: state.userTypes[user.id] };
      });
      return {
        ...state,
        userAndUserTypes: connectedUserAndUsertype,
        userTableList: connectedUserAndUsertype,
        isLoading: {
          ...state.isLoading,
          loadingUsers: false,
        },
      };
    case CONVERT_TO_MENTOR:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          convertingMentor: true,
        },
      };
    case CONVERT_TO_MENTOR_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.id === action.payload.id ? action.payload : user,
        ),
        userTableList: state.users.map(user =>
          user.id === action.payload.id ? action.payload : user,
        ),
        isLoading: {
          ...state.isLoading,
          convertingMentor: false,
        },
      };
    case CONVERT_TO_MENTOR_FAILURE:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          convertingMentor: false,
        },
      };
    case ADMIN_DELETE_USER_SUCCESS: {
      const users = state.users.filter(user => user.id !== action.payload.uid);
      return {
        ...state,
        users,
        userTableList: users,
      };
    }
    case RECEIVE_COMPLETED_CHATROOMS:
      return {
        ...state,
        completedChatRoomsCount: action.payload.length,
      };
    case RECEIVE_UNCLAIMED_CHATROOMS: {
      const unClaimedAndInCompleted = action.payload.filter(c => c.isComplete === false);
      const unClaimedAndCompleted = action.payload.filter(c => c.isComplete === true);

      return {
        ...state,
        unClaimedChatRooms: unClaimedAndInCompleted,
        cancelledChatRoomsCount: unClaimedAndCompleted.length,
        unClaimedChatRoomsCount: unClaimedAndInCompleted.length,
      };
    }
    default:
      return state;
  }
};

export const mentorAccessRequestsReducer = (
  state = { ...DEFAULT_API_STATE, byId: {} },
  action: RootAction,
): APIState & { byId: ById<MentorAccessRequest> } => {
  switch (action.type) {
    case RECEIVE_MENTOR_ACCESS_REQUESTS:
      return {
        ...state,
        byId: action.payload.requests.reduce(
          (acc, cur) => ({ ...acc, [cur.uid]: cur }),
          {},
        ),
      };
    default:
      return state;
  }
};

export const customLogoUploadReducer = (
  state = DEFAULT_API_STATE,
  action: RootAction,
): APIState => {
  switch (action.type) {
    case UPLOAD_CUSTOM_LOGO:
      return { ...state, isLoading: true };
    case UPLOAD_CUSTOM_LOGO_SUCCESS:
      return { ...state, isLoading: false };
    case UPLOAD_CUSTOM_LOGO_ERROR:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export const meteredDiscussionConfigReducer = (
  state = DEFAULT_API_STATE,
  action: RootAction,
): APIState & { config?: MeteredDiscussionConfig } => {
  switch (action.type) {
    case SAVE_METERED_DISCUSSION_CONFIG:
      return { ...state, isLoading: true, config: action.payload.data };
    case SAVE_METERED_DISCUSSION_CONFIG_SUCCESS:
      return { ...state, isLoading: false, config: action.payload.data };
    case SAVE_METERED_DISCUSSION_CONFIG_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    case GET_METERED_DISCUSSION_CONFIG:
      return { ...state, isLoading: true };
    case GET_METERED_DISCUSSION_CONFIG_SUCCESS:
      return { ...state, isLoading: false, config: action.payload.data };
    case GET_METERED_DISCUSSION_CONFIG_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    default:
      return state;
  }
};

const reportsReducer = (
  state = DEFAULT_API_STATE,
  action: RootAction,
): APIState & { data?: ChatRoomMetrics[] } => {
  switch (action.type) {
    case RECEIVE_REPORTS:
      return { ...state, isLoading: false, data: action.payload.data };
    case FETCH_REPORTS:
      return { ...state, isLoading: true };
    default:
      return state;
  }
};

const announcementsReducer = (
  state = DEFAULT_API_STATE,
  action: RootAction,
): APIState & { announcements?: ById<Announcements> } => {
  switch (action.type) {
    case FETCH_ANNOUNCEMENTS:
      return { ...state, isLoading: true };
    case FETCH_ANNOUNCEMENTS_SUCCESS:
      return { ...state, isLoading: false, announcements: action.payload.announcements };
    case FETCH_ANNOUNCEMENTS_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    default:
      return state;
  }
};

const popularTagsReducer = (
  state = { ...DEFAULT_API_STATE },
  action: RootAction,
): APIState & { popularTags?: PopularTag[] } => {
  switch (action.type) {
    case RECEIVE_POPULAR_TAGS:
      return { ...state, isLoading: false, popularTags: action.payload.data };
    case FETCH_POPULAR_TAGS:
      return { ...state, isLoading: true };
    default:
      return state;
  }
};

const apiKeysReducer = (
  state: APIState & { apiKeys?: ApiKey[] } = { ...DEFAULT_API_STATE },
  action: RootAction,
): APIState & { apiKeys?: ApiKey[] } => {
  switch (action.type) {
    case LIST_API_KEYS_SUCCESS:
      return { ...state, isLoading: false, apiKeys: action.payload.data };
    case LIST_API_KEYS:
      return { ...state, isLoading: true };
    default:
      return state;
  }
};

const ratingReducer = (
  state = DEFAULT_API_STATE,
  action: RootAction,
): APIState & {
  data?: UserRating[];
  allComments?: string[];
  totalRating?: { avg: number; total: number };
} => {
  switch (action.type) {
    case RECEIVE_RATINGS: {
      const ratings = action.payload.data;
      const comments = ratings.flatMap(r => r.comments.filter(c => c.length > 0));
      const totalRating = ratings.reduce(
        (acc, cur) => ({
          sumNumRatings: acc.sumNumRatings + cur.totalRating.total,
          sumAvg: acc.sumAvg + cur.totalRating.avg,
        }),
        { sumNumRatings: 0, sumAvg: 0 },
      );

      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        allComments: comments.reverse(),
        totalRating: {
          avg: totalRating.sumAvg / ratings.length,
          total: totalRating.sumNumRatings,
        },
      };
    }
    case FETCH_RATINGS:
      return { ...state, isLoading: true };
    default:
      return state;
  }
};

export const adminReducer = combineReducers({
  admin: oldAdminReducer,
  customLogo: customLogoUploadReducer,
  reports: reportsReducer,
  ratings: ratingReducer,
  popularTags: popularTagsReducer,
  mentorAccessRequests: mentorAccessRequestsReducer,
  announcements: announcementsReducer,
  apiKeys: apiKeysReducer,
  meteredDiscussionConfig: meteredDiscussionConfigReducer,
});

// selectors
const getAdmin = (state: AppState) => state.admin.admin;
export const getCustomlogoState = (state: AppState) => state.admin.customLogo;
export const getAdminUsers = (state: AppState) => getAdmin(state).users;
export const getPopularTags = (state: AppState) => state.admin.popularTags;
export const getHasFetched = (state: AppState) => {
  return getAdmin(state).hasFetched.users && getAdmin(state).hasFetched.userTypes;
};
export const getAdminUserTableList = (state: AppState) => {
  return getAdmin(state).userTableList;
};
export const getIsLoadingAdmin = (state: AppState) => getAdmin(state).isLoading;
export const getSelectedMentorChatRooms = (state: AppState) => {
  if (!getAdmin(state).selectedMentor.mentor) return null;

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return getAdmin(state).selectedMentor.mentor.chatRooms;
};
export const getInCompleteChatRoomsCount = (state: AppState) => {
  return getAdmin(state).inCompleteChatRoomsCount;
};
export const getInQueueChatRoomsCount = (state: AppState) => {
  return getAdmin(state).inQueueCount;
};
export const getAvailableMentors = (state: AppState) => {
  return Object.values(getAdmin(state).userTypes).filter(user => user.isAvailable);
};
export const getAllMentors = (state: AppState) => {
  return Object.values(getAdmin(state).userTypes).filter(
    user => user.isAvailable || user.isAvailable === false,
  );
};
export const getUserTypes = (state: AppState) => {
  return Object.values(getAdmin(state).userTypes);
};
export const getTotalMentors = (state: AppState) => {
  return getAdmin(state).users.filter(u => u.roles.mentor);
};

export const getCompletedChatRoomsCount = (state: AppState) =>
  getAdmin(state).completedChatRoomsCount;

export const getUnClaimedChatRoomsCount = (state: AppState) =>
  getAdmin(state).unClaimedChatRoomsCount;
export const getCancelledChatRoomsCount = (state: AppState) =>
  getAdmin(state).cancelledChatRoomsCount;

export const getAdminAnnouncements = (state: AppState) => state.admin.announcements;

export const selectMeteredDiscussionConfig = (state: AppState) =>
  state.admin.meteredDiscussionConfig;

const doesMentorSupportTopic = (mentor: UserType, topic: string, subTopic: string) => {
  if (!mentor.mentorPreferences) return false;
  if (!mentor.mentorPreferences.topics[topic]) return false;
  return Object.values(mentor.mentorPreferences.topics[topic]).some(
    sub => sub === subTopic,
  );
};
const isChatRoomRelatedToTopic = (
  chatRoom: ChatRoomEntity,
  topic: string,
  subTopic: string,
) => {
  if (!chatRoom.additionalInfo) return false;
  return [topic, subTopic].every(top => chatRoom.additionalInfo?.topic?.includes(top));
};

const aggregateByTopic = <T>(
  topics: ById<Topic>,
  collection: T[],
  predicate: (type: T, topic: string, subTopic: string) => boolean,
) => {
  return Object.values(topics).flatMap(topic => {
    if (!topic.subTopics) return [];
    return Object.values(topic.subTopics).map(sub => ({
      name: `${topic.name} - ${sub.name}`,
      value: collection.filter(value => predicate(value, topic.id, sub.id)).length,
    }));
  });
};

const aggregateMentorsByTopic = (topics: ById<Topic>, mentors: UserType[]) => {
  return aggregateByTopic<UserType>(topics, mentors, doesMentorSupportTopic);
};

const aggregateChatRoomsByTopic = (topics: ById<Topic>, chatRooms: ChatRoomEntity[]) => {
  return aggregateByTopic<ChatRoomEntity>(topics, chatRooms, isChatRoomRelatedToTopic);
};

export const getAvailableMentorsByTopic = (
  state: AppState,
): { name: string; value: number }[] => {
  const { topics } = selectServerData(state);
  const mentors = getAvailableMentors(state);
  if (!topics) return [];
  return aggregateMentorsByTopic(topics, mentors);
};

export const getTotalMentorsByTopic = (
  state: AppState,
): { name: string; value: number }[] => {
  const { topics } = selectServerData(state);
  const mentors = getAllMentors(state);
  if (!topics) return [];
  return aggregateMentorsByTopic(topics, mentors);
};

export const getUnClaimedChatRoomsByTopic = (
  state: AppState,
): { name: string; value: number }[] => {
  const { unClaimedChatRooms } = getAdmin(state);
  const { topics } = selectServerData(state);
  if (!topics) return [];
  return aggregateChatRoomsByTopic(topics, unClaimedChatRooms);
};

export const getMentorAccessRequests = (state: AppState) =>
  state.admin.mentorAccessRequests.byId;

export const getReports = (state: AppState) => state.admin.reports;
export const getUserRatings = (state: AppState) => state.admin.ratings;

export const getApiKeys = (state: AppState) => state.admin.apiKeys;
