import { createAction } from 'typesafe-actions';

import { ApiKey, RoleUser, MSClaims } from '../../services/backendService';
import { Mentor, ChatRoomEntity, UserType } from '../../services/db';
import { LoginProviders } from '../Server';
import {
  ChatRoomMetrics,
  UserRating,
  PopularTag,
  MentorAccessRequest,
  Announcements,
  UserRole,
  MeteredDiscussionConfig,
} from '../../services/database/types';

import { ById } from '.';
import * as ActionTypes from './actionTypes';

import { Topic } from '..';

export const fetchUsers = createAction(ActionTypes.FETCH_USERS);
export const fetchMentor = createAction(
  ActionTypes.FETCH_MENTOR,
  action => (uid: string) => action(uid),
);
export const fetchInCompleteChatRooms = createAction(
  ActionTypes.FETCH_INCOMPLETE_CHATROOMS,
);
export const fetchCompletedChatRooms = createAction(
  ActionTypes.FETCH_COMPLETED_CHATROOMS,
);

export const receiveCompletedChatRooms = createAction(
  ActionTypes.RECEIVE_COMPLETED_CHATROOMS,
  action => (chatRooms: ChatRoomEntity[]) => action(chatRooms),
);
export const fetchUserTypes = createAction(ActionTypes.FETCH_USERTYPES);

export const receiveUsers = createAction(
  ActionTypes.RECEIVE_USERS,
  action => (users: RoleUser[]) => action(users),
);
export const receiveMentor = createAction(
  ActionTypes.RECEIVE_MENTOR,
  action => (mentor: Mentor) => action(mentor),
);
export const receiveInCompleteChatRooms = createAction(
  ActionTypes.RECEIVE_INCOMPLETE_CHATROOMS,
  action => (chatRooms: ChatRoomEntity[]) => action(chatRooms),
);

export const receiveUserTypes = createAction(
  ActionTypes.RECEIVE_USERTYPES,
  action => (userTypes: ById<UserType>) => action(userTypes),
);

export const connectUsersAndUserTypes = createAction(
  ActionTypes.CONNECT_USERS_AND_USERTYPES,
);
export const convertToMentor = createAction(
  ActionTypes.CONVERT_TO_MENTOR,
  action => (uid: string, claims: MSClaims) => action({ uid, claims }),
);
export const convertToMentorSuccess = createAction(
  ActionTypes.CONVERT_TO_MENTOR_SUCCESS,
  action => (user: RoleUser) => action(user),
);
export const convertToMentorFailure = createAction(
  ActionTypes.CONVERT_TO_MENTOR_FAILURE,
  action => (uid: string) => action(uid),
);

export const fetchUnClaimedChatRooms = createAction(
  ActionTypes.FETCH_UNCLAIMED_CHATROOMS,
);

export const receiveUnClaimedChatRooms = createAction(
  ActionTypes.RECEIVE_UNCLAIMED_CHATROOMS,
  action => (chatRooms: ChatRoomEntity[]) => action(chatRooms),
);
export const saveServerTopics = createAction(
  ActionTypes.SAVE_SERVER_TOPICS,
  action => (topics: ById<Topic>) => action(topics),
);

export const saveDefaultLanguage = createAction(
  ActionTypes.SAVE_DEFAULT_LANGUAGE,
  action => (lang: string) => action(lang),
);

export const saveDefaultRole = createAction(
  ActionTypes.SAVE_DEFAULT_ROLE,
  action => (role: UserRole) => action(role),
);

export const subscribeMentorAccessRequests = createAction(
  ActionTypes.SUBSCRIBE_MENTOR_ACCESS_REQUESTS,
);

export const mentorAccessRequestsReceived = createAction(
  ActionTypes.RECEIVE_MENTOR_ACCESS_REQUESTS,
  action => (requests: MentorAccessRequest[]) => action({ requests }),
);

export const updateLoginProviders = createAction(
  ActionTypes.UPDATE_LOGIN_PROVIDERS,
  action => (loginProviders: LoginProviders) => action({ loginProviders }),
);

export const uploadCustomLogo = createAction(
  ActionTypes.UPLOAD_CUSTOM_LOGO,
  action => (dataString: string) => action(dataString),
);
export const uploadCustomLogoSuccess = createAction(
  ActionTypes.UPLOAD_CUSTOM_LOGO_SUCCESS,
);
export const uploadCustomLogoError = createAction(ActionTypes.UPLOAD_CUSTOM_LOGO_ERROR);
export const fetchReports = createAction(ActionTypes.FETCH_REPORTS);
export const receiveReports = createAction(
  ActionTypes.RECEIVE_REPORTS,
  action => (data: ChatRoomMetrics[]) => action({ data }),
);

export const fetchRatings = createAction(ActionTypes.FETCH_RATINGS);
export const receiveRatings = createAction(
  ActionTypes.RECEIVE_RATINGS,
  action => (data: UserRating[]) => action({ data }),
);

export const fetchPopularTags = createAction(ActionTypes.FETCH_POPULAR_TAGS);
export const receivePopularTags = createAction(
  ActionTypes.RECEIVE_POPULAR_TAGS,
  action => (data: PopularTag[]) => action({ data }),
);

export const saveAnnouncement = createAction(
  ActionTypes.SAVE_ANNOUNCEMENT,
  action => (messages: string[], forGroup: 'students' | 'mentors') =>
    action({ messages, forGroup }),
);

export const saveServiceName = createAction(
  ActionTypes.SAVE_SERVICE_NAME,
  action => (name: string) => action({ name }),
);

export const fetchAdminAnnouncements = createAction(ActionTypes.FETCH_ANNOUNCEMENTS);
export const fetchAdminAnnouncementsSuccess = createAction(
  ActionTypes.FETCH_ANNOUNCEMENTS_SUCCESS,
  action => (announcements: ById<Announcements>) => action({ announcements }),
);
export const fetchAdminAnnouncementsError = createAction(
  ActionTypes.FETCH_ANNOUNCEMENTS_ERROR,
  action => (error: string) => action({ error }),
);

export const adminDeleteUser = createAction(
  ActionTypes.ADMIN_DELETE_USER,
  action => (uid: string) => action({ uid }),
);

export const adminDeleteUserSuccess = createAction(
  ActionTypes.ADMIN_DELETE_USER_SUCCESS,
  action => (uid: string) => action({ uid }),
);

export const adminDeleteUserError = createAction(
  ActionTypes.ADMIN_DELETE_USER_ERROR,
  action => (error: string) => action({ error }),
);

export const saveMeteredDiscussionConfig = createAction(
  ActionTypes.SAVE_METERED_DISCUSSION_CONFIG,
  action => (data: MeteredDiscussionConfig) => action({ data }),
);

export const saveMeteredDiscussionConfigSuccess = createAction(
  ActionTypes.SAVE_METERED_DISCUSSION_CONFIG_SUCCESS,
  action => (data: MeteredDiscussionConfig) => action({ data }),
);

export const saveMeteredDiscussionConfigError = createAction(
  ActionTypes.SAVE_METERED_DISCUSSION_CONFIG_ERROR,
  action => (error: string) => action({ error }),
);

export const getMeteredDiscussionConfig = createAction(
  ActionTypes.GET_METERED_DISCUSSION_CONFIG,
);

export const getMeteredDiscussionConfigSuccess = createAction(
  ActionTypes.GET_METERED_DISCUSSION_CONFIG_SUCCESS,
  action => (data: MeteredDiscussionConfig) => action({ data }),
);

export const getMeteredDiscussionConfigError = createAction(
  ActionTypes.GET_METERED_DISCUSSION_CONFIG_ERROR,
  action => (error: string) => action({ error }),
);

export const createApiKey = createAction(ActionTypes.CREATE_API_KEY);
export const createApiKeySuccess = createAction(
  ActionTypes.CREATE_API_KEY_SUCCESS,
  action => (data: ApiKey) => action({ data }),
);
export const createApiKeyError = createAction(
  ActionTypes.CREATE_API_KEY_ERROR,
  action => (error: string) => action({ error }),
);

export const listApiKeys = createAction(ActionTypes.LIST_API_KEYS);
export const listApiKeysSuccess = createAction(
  ActionTypes.LIST_API_KEYS_SUCCESS,
  action => (data: ApiKey[]) => action({ data }),
);
export const listApiKeysError = createAction(
  ActionTypes.LIST_API_KEYS_ERROR,
  action => (error: string) => action({ error }),
);

export const deleteApiKey = createAction(
  ActionTypes.DELETE_API_KEY,
  action => (data: ApiKey) => action({ data }),
);
export const deleteApiKeySuccess = createAction(
  ActionTypes.DELETE_API_KEY_SUCCESS,
  action => (data: ApiKey) => action({ data }),
);
export const deleteApiKeyError = createAction(
  ActionTypes.DELETE_API_KEY_ERROR,
  action => (error: string) => action({ error }),
);
