import { filter, map, mergeMap, catchError, switchMap, takeUntil } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { combineEpics } from 'redux-observable';
import { from, empty } from 'rxjs';

import { catchHandler } from '../utils';
import { SIGN_OUT } from '../User/actionTypes';
import { getUserId } from '../Auth';

import { setUserType } from './actions';
import {
  SUBSCRIBE,
  SET_AVAILABLE,
  SET_UNAVAILABLE,
  SEND_GENERAL_FEEDBACK,
  SET_SUPPORTED_TOPIC,
  UPDATE_NICKNAME,
  UPDATE_LAST_SEEN_INFO_BANNER,
  ACCESS_REQUESTED,
} from './actionTypes';

import { RootEpic } from '..';

export const userTypeSubscriptionEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(SUBSCRIBE)),
    switchMap(action =>
      database.usertype
        .get(action.payload)
        .pipe(
          takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)), catchError(catchHandler))),
        ),
    ),
    map(userType => setUserType(userType || {})),
    catchError(catchHandler),
  );

export const setAvailableEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(SET_AVAILABLE)),
    mergeMap(() =>
      from(database.usertype.setAvailable(true, getUserId(state$.value)!)).pipe(
        mergeMap(() => empty()),
        catchError(catchHandler),
      ),
    ),
  );
export const setSupportedTopicEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(SET_SUPPORTED_TOPIC)),
    mergeMap(({ payload }) =>
      from(
        database.usertype.setMentorSupportedTopic(getUserId(state$.value)!, payload),
      ).pipe(
        mergeMap(() => empty()),
        catchError(catchHandler),
      ),
    ),
  );

export const setUnAvailableEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(SET_UNAVAILABLE)),
    mergeMap(() =>
      from(database.usertype.setAvailable(false, getUserId(state$.value)!)).pipe(
        mergeMap(() => empty()),
        catchError(catchHandler),
      ),
    ),
  );

export const sendGeneralFeedbackEpic: RootEpic = (action$, state$, { db }) =>
  action$.pipe(
    filter(isOfType(SEND_GENERAL_FEEDBACK)),
    mergeMap(action => {
      const userId = getUserId(state$.value)!;
      const review = {
        ...action.payload,
        userId,
        timestamp: new Date().getTime(),
      };
      return from(db.addGeneralReview(review)).pipe(
        mergeMap(() => empty()),
        catchError(catchHandler),
      );
    }),
  );

export const updateNickNameEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(UPDATE_NICKNAME)),
    mergeMap(action =>
      from(database.usertype.setNickName(action.payload, getUserId(state$.value)!)).pipe(
        mergeMap(() => empty()),
        catchError(catchHandler),
      ),
    ),
  );

export const updateLastSeenInfoBannerEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(UPDATE_LAST_SEEN_INFO_BANNER)),
    mergeMap(() =>
      from(database.usertype.setLastSeenInfoBanner(getUserId(state$.value)!)).pipe(
        mergeMap(() => empty()),
        catchError(catchHandler),
      ),
    ),
  );

export const requestMentorAccessEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(ACCESS_REQUESTED)),
    switchMap(() => {
      return from(
        database.mentorAccessRequests.requestAccess(getUserId(state$.value)!),
      ).pipe(
        mergeMap(() => empty()),
        catchError(catchHandler),
      );
    }),
  );

export const userEpic = combineEpics(
  userTypeSubscriptionEpic,
  setAvailableEpic,
  setUnAvailableEpic,
  sendGeneralFeedbackEpic,
  setSupportedTopicEpic,
  updateNickNameEpic,
  updateLastSeenInfoBannerEpic,
  requestMentorAccessEpic,
);
