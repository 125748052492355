import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Button, Popconfirm } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ProfileCircle from '../../_atoms/ProfileCircle';
import {
  completeChatRoom,
  getIsMentor,
  isChatRoomCompleted,
  getIsMeteredDiscussionsEnabled,
  getMeteredDiscussionById,
  startMeteredDiscussionTimer,
} from '../../../modules';
import { useIsDesktop, useQuery, useTypedSelector } from '../../../hooks';
import { Icons } from '../../Icons';

type Props = {
  chatRoomId: string;
  name?: string;
  mentorId?: string;
  avatarColor?: string;
  adverserialSeenMessageTimestamp?: number;
  isTyping?: boolean;
};
export const ChatRoomHeader = (props: Props) => {
  const {
    avatarColor,
    chatRoomId,
    name,
    mentorId,
    adverserialSeenMessageTimestamp,
    isTyping,
  } = props;

  const messageTimestamp = adverserialSeenMessageTimestamp
    ? new Date(adverserialSeenMessageTimestamp)
    : null;
  const dispatch = useDispatch();
  const { t } = useTranslation('ChatRoom');
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const { search } = useQuery();
  const isMentor = useTypedSelector(getIsMentor);
  const isMeteredDiscussionEnabled = useTypedSelector(getIsMeteredDiscussionsEnabled);
  const meteredDiscussion = useTypedSelector(state =>
    getMeteredDiscussionById(state, chatRoomId),
  );

  const isCompleted = useTypedSelector(state => isChatRoomCompleted(state, chatRoomId));

  const onCompleteChatRoom = () => {
    dispatch(completeChatRoom(chatRoomId));

    history.push(`/main/review${search}`, {
      mentorId: mentorId,
      chatRoomId: chatRoomId,
      templateId: isMentor ? 'mentortags' : 'onChatRoomCompleted',
    });
  };

  return (
    <RowStyled isDesktop={isDesktop}>
      {!isDesktop && (
        <IconWrapper onClick={() => history.push('/main')}>
          <Icons.ArrowLeft />
        </IconWrapper>
      )}
      {isDesktop && (
        <ProfileCircleStyled
          width={isDesktop ? 40 : 25}
          name={name}
          color={avatarColor ? avatarColor : '#D3D3D3'}
        />
      )}
      <Info>
        <h5>{name || t('No mentor yet')}</h5>
        <p>
          {isTyping && t('is writing') + '...'}
          {messageTimestamp &&
            !isTyping &&
            t('last_seen', {
              defaultValue: 'Last seen: {{time}}',
              time: moment(messageTimestamp, 'YYYYMMDD').fromNow(),
            })}
        </p>
      </Info>
      {isMeteredDiscussionEnabled && isMentor && (
        <Popconfirm
          title="Are you sure you want to start the timer?"
          onConfirm={() => {
            dispatch(startMeteredDiscussionTimer(chatRoomId));
          }}
          onCancel={() => null}
          okText="Yes"
          cancelText="No"
          placement="bottom"
          style={{ zIndex: 9999999 }}
        >
          <Button
            type="ghost"
            style={{
              borderRadius: 10,
              paddingLeft: 20,
              paddingRight: 20,
              marginRight: 10,
            }}
            disabled={!!meteredDiscussion || isCompleted}
          >
            {'Start timer'}
          </Button>
        </Popconfirm>
      )}
      <Popconfirm
        title="Are you sure delete this task?"
        onConfirm={onCompleteChatRoom}
        onCancel={() => null}
        okText="Yes"
        cancelText="No"
        placement="bottom"
        style={{ zIndex: 9999999 }}
      >
        <Button
          type="primary"
          style={{ borderRadius: 10, paddingLeft: 20, paddingRight: 20 }}
          disabled={isCompleted}
          onClick={() => {
            onCompleteChatRoom();
          }}
        >
          {t('Resolve')}
        </Button>
      </Popconfirm>
    </RowStyled>
  );
};

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  flex: 1;
  text-align: left;
  padding: 0 20px;
  & h5 {
    font-size: 20px;
    line-height: 16px;
    margin-bottom: 5px;
  }
  & p {
    color: #939393;
    margin: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;

const ProfileCircleStyled = styled(ProfileCircle)`
  margin-left: 20px;
`;

const RowStyled = styled.div<{ isDesktop: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ isDesktop }) => (isDesktop ? '90px' : '60px')};
  padding: 0 20px;
  width: 100%;
  background-color: #fffdfb;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
`;
