import { createAction } from 'typesafe-actions';

import { ReviewUI, MentorPreferences } from '../../services/db';

import * as ActionTypes from './actionTypes';
import { UserTypeState } from './reducer';

export const setAvailable = createAction(ActionTypes.SET_AVAILABLE);
export const setUnAvailable = createAction(ActionTypes.SET_UNAVAILABLE);
export const subscribeToUserType = createAction(
  ActionTypes.SUBSCRIBE,
  action => (uid: string) => action(uid),
);
export const setUserType = createAction(
  ActionTypes.SET_USER_TYPE,
  action => (data: Partial<UserTypeState>) => action(data),
);

export const sendGeneralFeedback = createAction(
  ActionTypes.SEND_GENERAL_FEEDBACK,
  action => (data: ReviewUI) => action(data),
);
export const setSupportedTopics = createAction(
  ActionTypes.SET_SUPPORTED_TOPIC,
  action => (mentorPreferences: MentorPreferences) => action(mentorPreferences),
);
export const updateNickName = createAction(
  ActionTypes.UPDATE_NICKNAME,
  action => (name: string) => action(name),
);
export const updateLastSeenInfoBanner = createAction(
  ActionTypes.UPDATE_LAST_SEEN_INFO_BANNER,
);

export const requestMentorAccess = createAction(ActionTypes.ACCESS_REQUESTED);
