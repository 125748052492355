import { combineReducers } from 'redux';

import { AppState } from '../rootReducer';
import { ById } from '../Admin';
import { Announcements, UserRole } from '../../services/database/types';

import { SERVER_DATA_CHANGED, PUBLIC_DATA_RECEIVED } from './actionTypes';

import { RootAction } from '..';

export type SubTopic = {
  id: string;
  name: string;
  level: number;
  disabled?: boolean;
};

export type Topic = {
  id: string;
  name: string;
  subTopics?: {
    [topicId: string]: SubTopic;
  };
};

export type Product = {
  id: string;
  duration: number;
  name: string;
  disabled?: boolean;
};

export type QuestionTypesByTopic = {
  [questionTypeId: string]: {
    id: string;
    question: string;
  };
};
export interface ServerState {
  products?: {
    [productId: string]: Product;
  };
  questionTypes?: QuestionTypesByTopic;
  questionTypesByTopic?: {
    [topicId: string]: string[];
  };
  topics?: ById<Topic>;
  announcement?: Announcements;
}

const initialState: ServerState = {};

const protectedReducer = (state = initialState, action: RootAction): ServerState => {
  switch (action.type) {
    case SERVER_DATA_CHANGED:
      return action.payload;
    default:
      return state;
  }
};

export type LoginProviders = {
  google: boolean;
  vipps: boolean;
  feide: boolean;
  email: boolean;
};

export const fallbackProviders: LoginProviders = {
  google: false,
  vipps: false,
  feide: false,
  email: true,
};

export type PublicData = {
  loginProviders?: LoginProviders;
  custom?: {
    logo: boolean;
    name: string;
    billingEnabled?: boolean;
    customLogoEnabled?: boolean;
    externalApiEnabled?: boolean;
    meteredDiscussionEnabled?: boolean;
    terms?: string;
    loginDisclaimer?: string;
  };
  defaultLanguage?: { lang: string };
  defaultRole?: { role: UserRole };
};

export const publicReducer = (state = {}, action: RootAction): PublicData => {
  switch (action.type) {
    case PUBLIC_DATA_RECEIVED:
      return action.payload || {};
    default:
      return state;
  }
};

export const serverReducer = combineReducers({
  protected: protectedReducer,
  public: publicReducer,
});

// selectors
export const selectServerData = (state: AppState) => state.server.protected;
export const getPublicData = (state: AppState) => state.server.public;
export const getIsBillingEnabled = (state: AppState) =>
  getPublicData(state).custom?.billingEnabled;

export const getIsExternalApiEnabled = (state: AppState) =>
  getPublicData(state).custom?.externalApiEnabled;

export const getLoginProviders = (state: AppState) => getPublicData(state).loginProviders;
export const getHasCustomLogo = (state: AppState) => getPublicData(state).custom?.logo;
export const getIsCustomLogoEnabled = (state: AppState) =>
  getPublicData(state).custom?.customLogoEnabled;
export const getIsMeteredDiscussionsEnabled = (state: AppState) =>
  getPublicData(state).custom?.meteredDiscussionEnabled;
export const getServiceName = (state: AppState) => getPublicData(state).custom?.name;
export const getCustomTermsUrl = (state: AppState) =>
  getPublicData(state).custom?.terms || 'https://www.snapmentor.no/terms-of-use';
export const getCustomPrivacyPolicyUrl = (state: AppState) =>
  getPublicData(state).custom?.terms || 'https://www.snapmentor.no/privacy-policy';
export const getDisclaimer = (state: AppState) =>
  getPublicData(state).custom?.loginDisclaimer || '';

export const getQuestionTypes = (state: AppState) =>
  selectServerData(state).questionTypes;
export const getAnnouncement = (state: AppState) => selectServerData(state).announcement;

export const getTopics = (state: AppState) => selectServerData(state).topics;

export const getDefaultLanguage = (state: AppState) =>
  getPublicData(state).defaultLanguage?.lang;

export const getDefaultRole = (state: AppState) => getPublicData(state).defaultRole?.role;

export const getLoginAuthority = (state: AppState) => {
  const { tenantName } = state.env.config.azure;
  const loginProviders = getPublicData(state).loginProviders;
  let authority = 'B2C_1A_signup_signin';
  const rootAuthorityUrl = `https://${tenantName}.b2clogin.com/tfp/${tenantName}.onmicrosoft.com/`;
  const authorityUrl = `${rootAuthorityUrl}${authority}`;
  if (!loginProviders) return authorityUrl;

  const { google, feide, email } = loginProviders;

  if (feide && email) authority = 'B2C_1A_signup_signin_feide_email';
  if (feide && google) authority = 'B2C_1A_signup_signin_google_feide';
  if (feide && !email && !google) authority = 'B2C_1A_signup_signin_feide';
  if (email && google) authority = 'B2C_1A_signup_signin';

  return `${rootAuthorityUrl}${authority}`;
};

export const getForgotPasswordAuthority = (state: AppState) => {
  const { tenantName } = state.env.config.azure;
  const authority = 'B2C_1A_PasswordReset';
  return `https://${tenantName}.b2clogin.com/tfp/${tenantName}.onmicrosoft.com/${authority}`;
};
