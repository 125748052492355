import {
  filter,
  mergeMap,
  catchError,
  map,
  switchMap,
  takeUntil,
  mapTo,
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { combineEpics } from 'redux-observable';
import * as Sentry from '@sentry/browser';
import { from, empty, combineLatest, of } from 'rxjs';

import { SIGN_OUT } from '../User';
import { catchHandler } from '../utils';
import { requestCustomLogo } from '../Blobs';
import { getUserId, getIdToken } from '../Auth';

import {
  receiveUsers,
  receiveMentor,
  convertToMentorFailure,
  convertToMentorSuccess,
  receiveUserTypes,
  connectUsersAndUserTypes,
  receiveCompletedChatRooms,
  receiveUnClaimedChatRooms,
  receiveInCompleteChatRooms,
  mentorAccessRequestsReceived,
  receiveReports,
  receiveRatings,
  receivePopularTags,
  fetchAdminAnnouncementsSuccess,
  fetchAdminAnnouncementsError,
  adminDeleteUserSuccess,
  adminDeleteUserError,
  listApiKeysSuccess,
  listApiKeysError,
  createApiKeySuccess,
  createApiKeyError,
  saveMeteredDiscussionConfigSuccess,
  saveMeteredDiscussionConfigError,
  getMeteredDiscussionConfigSuccess,
  getMeteredDiscussionConfigError,
} from './actions';
import {
  FETCH_USERS,
  CONVERT_TO_MENTOR,
  FETCH_MENTOR,
  FETCH_INCOMPLETE_CHATROOMS,
  FETCH_USERTYPES,
  RECEIVE_USERS,
  RECEIVE_USERTYPES,
  FETCH_COMPLETED_CHATROOMS,
  FETCH_UNCLAIMED_CHATROOMS,
  SAVE_SERVER_TOPICS,
  SAVE_DEFAULT_LANGUAGE,
  SUBSCRIBE_MENTOR_ACCESS_REQUESTS,
  UPLOAD_CUSTOM_LOGO,
  UPDATE_LOGIN_PROVIDERS,
  FETCH_REPORTS,
  FETCH_RATINGS,
  FETCH_POPULAR_TAGS,
  CONVERT_TO_MENTOR_SUCCESS,
  SAVE_ANNOUNCEMENT,
  FETCH_ANNOUNCEMENTS,
  SAVE_SERVICE_NAME,
  ADMIN_DELETE_USER,
  LIST_API_KEYS,
  CREATE_API_KEY,
  DELETE_API_KEY,
  DELETE_API_KEY_SUCCESS,
  CREATE_API_KEY_SUCCESS,
  SAVE_DEFAULT_ROLE,
  SAVE_METERED_DISCUSSION_CONFIG,
  GET_METERED_DISCUSSION_CONFIG,
} from './actionTypes';

import { RootEpic } from '..';
export const getUsersEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(FETCH_USERS)),
    switchMap(() => backendService.getAllUsers(getIdToken(state$.value)!)),
    map(receiveUsers),
    catchError(() => empty()),
  );

export const getInCompleteChatRoomsEpic: RootEpic = (action$, state$, { db }) =>
  action$.pipe(
    filter(isOfType(FETCH_INCOMPLETE_CHATROOMS)),
    switchMap(() => {
      return db
        .getInCompleteChatRooms()
        .pipe(takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))));
    }),
    map(val => Object.values(val)),
    map(receiveInCompleteChatRooms),
    catchError(() => empty()),
  );
export const getUnClaimedChatRoomsEpic: RootEpic = (action$, state$, { db }) =>
  action$.pipe(
    filter(isOfType(FETCH_UNCLAIMED_CHATROOMS)),
    switchMap(() => {
      return db
        .getUnClaimedChatRooms()
        .pipe(takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))));
    }),
    map(val => Object.values(val)),
    map(receiveUnClaimedChatRooms),
    catchError(() => empty()),
  );
export const getCompletedChatRoomsEpic: RootEpic = (action$, state$, { db }) =>
  action$.pipe(
    filter(isOfType(FETCH_COMPLETED_CHATROOMS)),
    switchMap(() => {
      return db
        .getCompletedChatRooms()
        .pipe(takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))));
    }),
    map(receiveCompletedChatRooms),
    catchError(() => empty()),
  );

export const getMentorEpic: RootEpic = (action$, state$, { db }) =>
  action$.pipe(
    filter(isOfType(FETCH_MENTOR)),
    switchMap(action => {
      return db.getMentor(action.payload);
    }),
    map(mentor => {
      return receiveMentor(mentor);
    }),
    catchError(() => empty()),
  );

export const convertUserToMentorEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(CONVERT_TO_MENTOR)),
    mergeMap(action =>
      backendService
        .setClaims(action.payload.uid, action.payload.claims, getIdToken(state$.value)!)
        .pipe(
          map(user => convertToMentorSuccess(user)),
          catchError(() => of(convertToMentorFailure(action.payload.uid))),
        ),
    ),
    catchError(() => empty()),
  );

export const getUserTypesEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(FETCH_USERTYPES)),
    switchMap(() => {
      return database.admin.usertype.list().pipe(
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
        map(userTypes => {
          return receiveUserTypes(userTypes);
        }),
      );
    }),
    catchError(() => empty()),
  );

export const connectUsersAndUserTypesEpic: RootEpic = action$ =>
  combineLatest(
    action$.pipe(filter(isOfType(RECEIVE_USERS))),
    action$.pipe(filter(isOfType(RECEIVE_USERTYPES))),
  ).pipe(mapTo(connectUsersAndUserTypes()));

export const saveAnnouncementsEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(SAVE_ANNOUNCEMENT)),
    switchMap(({ payload: { messages, forGroup } }) =>
      from(database.announcements.save(messages, forGroup)).pipe(
        mergeMap(() => empty()),
        catchError(error => {
          Sentry.captureException(error);
          return empty();
        }),
      ),
    ),
  );

export const saveServerTopicsEpic: RootEpic = (action$, _, { db }) =>
  action$.pipe(
    filter(isOfType(SAVE_SERVER_TOPICS)),
    switchMap(({ payload }) => from(db.updateTopics(payload))),
    mergeMap(() => empty()),
  );
export const saveDefaultLanguageEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(SAVE_DEFAULT_LANGUAGE)),
    switchMap(({ payload }) => from(database.setDefaultLanguage(payload))),
    mergeMap(() => empty()),
  );

export const saveDefaultRoleEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(SAVE_DEFAULT_ROLE)),
    switchMap(({ payload }) => from(database.setDefaultRole(payload))),
    mergeMap(() => empty()),
  );

export const saveLoginProvidersEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(UPDATE_LOGIN_PROVIDERS)),
    switchMap(({ payload: { loginProviders } }) =>
      from(database.setLoginProviders(loginProviders)),
    ),
    mergeMap(() => empty()),
  );
export const saveServiceNameEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(SAVE_SERVICE_NAME)),
    switchMap(({ payload: { name } }) => from(database.setServiceName(name))),
    mergeMap(() => empty()),
  );

export const getMentorAccessRequestsEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(SUBSCRIBE_MENTOR_ACCESS_REQUESTS)),
    switchMap(() =>
      database.mentorAccessRequests.admin
        .list()
        .pipe(
          map(mentorAccessRequestsReceived),
          catchError(catchHandler),
          takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
        ),
    ),
  );
export const onMentorConvertedEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(CONVERT_TO_MENTOR_SUCCESS)),
    switchMap(({ payload }) =>
      from(
        database.mentorAccessRequests.admin.removeMentorAccessRequest(payload.id),
      ).pipe(
        mergeMap(() => empty()),
        catchError(catchHandler),
      ),
    ),
  );

export const uploadCustomLogoEpic: RootEpic = (action$, state$, { files, database }) =>
  action$.pipe(
    filter(isOfType(UPLOAD_CUSTOM_LOGO)),
    switchMap(({ payload }) =>
      from(files.admin.uploadCustomLogo(payload)).pipe(
        mergeMap(() => database.setHasCustomLogo(true)),
      ),
    ),
    map(() => requestCustomLogo()),
    catchError(catchHandler),
  );

export const reportsEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(FETCH_REPORTS)),
    switchMap(() => database.getChatRoomMetrics()),
    takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
    map(data => receiveReports(data)),
    catchError(catchHandler),
  );
export const announcementsEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(FETCH_ANNOUNCEMENTS)),
    switchMap(() =>
      database.announcements.admin.list().pipe(
        map(data => fetchAdminAnnouncementsSuccess(data)),
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
        catchError(e => of(fetchAdminAnnouncementsError(e.message))),
      ),
    ),
    catchError(catchHandler),
  );

export const ratingsEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(FETCH_RATINGS)),
    switchMap(() =>
      database.getUserRatings().pipe(
        map(data => receiveRatings(data)),
        catchError(catchHandler),
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
      ),
    ),
  );

export const popularTagsEpic: RootEpic = (action$, state$, { database }) =>
  action$.pipe(
    filter(isOfType(FETCH_POPULAR_TAGS)),
    switchMap(() =>
      database.getPopularTags().pipe(
        map(data => receivePopularTags(data)),
        catchError(catchHandler),
        takeUntil(action$.pipe(filter(isOfType(SIGN_OUT)))),
      ),
    ),
  );

export const adminDeleteUserEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(ADMIN_DELETE_USER)),
    mergeMap(action =>
      backendService.deleteUser(action.payload.uid, getIdToken(state$.value)!).pipe(
        map(() => adminDeleteUserSuccess(action.payload.uid)),
        catchError(() => of(adminDeleteUserError(action.payload.uid))),
      ),
    ),
  );

export const listApiKeysEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType([LIST_API_KEYS, DELETE_API_KEY_SUCCESS, CREATE_API_KEY_SUCCESS])),
    mergeMap(() =>
      backendService.apiKeys.list(getIdToken(state$.value)!).pipe(
        map(data => listApiKeysSuccess(data)),
        catchError(() => of(listApiKeysError('Unable to fetch api keys'))),
      ),
    ),
  );

export const createApiKeyEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(CREATE_API_KEY)),
    mergeMap(() =>
      backendService.apiKeys
        .create(getUserId(state$.value)!, getIdToken(state$.value)!)
        .pipe(
          map(data => createApiKeySuccess(data)),
          catchError(() => of(createApiKeyError('Unable to fetch api keys'))),
        ),
    ),
  );

export const deleteApiKeyEpic: RootEpic = (action$, state$, { backendService }) =>
  action$.pipe(
    filter(isOfType(DELETE_API_KEY)),
    mergeMap(action =>
      backendService.apiKeys
        .delete(
          getIdToken(state$.value)!,
          getUserId(state$.value)!,
          action.payload.data.apiKey,
        )
        .pipe(
          map(() => createApiKeySuccess(action.payload.data)),
          catchError(() => of(createApiKeyError('Unable to fetch api keys'))),
        ),
    ),
  );

export const saveMeteredDiscussionConfigEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(SAVE_METERED_DISCUSSION_CONFIG)),
    mergeMap(action =>
      from(database.discussions.saveMeteredDiscussionConfig(action.payload.data)).pipe(
        map(() => saveMeteredDiscussionConfigSuccess(action.payload.data)),
        catchError(() =>
          of(
            saveMeteredDiscussionConfigError(
              'Unable to save the meteredDiscussion config',
            ),
          ),
        ),
      ),
    ),
  );

export const getMeteredDiscussionConfigEpic: RootEpic = (action$, _, { database }) =>
  action$.pipe(
    filter(isOfType(GET_METERED_DISCUSSION_CONFIG)),
    mergeMap(() =>
      from(database.discussions.getMeteredDiscussionConfig()).pipe(
        map(data => getMeteredDiscussionConfigSuccess(data)),
        catchError(() =>
          of(
            getMeteredDiscussionConfigError(
              'Unable to fetch the meteredDiscussion config',
            ),
          ),
        ),
      ),
    ),
  );

export const rootAdminEpic = combineEpics(
  getUsersEpic,
  getMentorEpic,
  getUserTypesEpic,
  getInCompleteChatRoomsEpic,
  connectUsersAndUserTypesEpic,
  convertUserToMentorEpic,
  saveAnnouncementsEpic,
  announcementsEpic,
  getCompletedChatRoomsEpic,
  getUnClaimedChatRoomsEpic,
  saveServerTopicsEpic,
  saveDefaultLanguageEpic,
  onMentorConvertedEpic,
  getMentorAccessRequestsEpic,
  uploadCustomLogoEpic,
  saveLoginProvidersEpic,
  reportsEpic,
  ratingsEpic,
  popularTagsEpic,
  saveServiceNameEpic,
  adminDeleteUserEpic,
  listApiKeysEpic,
  createApiKeyEpic,
  deleteApiKeyEpic,
  saveDefaultRoleEpic,
  saveMeteredDiscussionConfigEpic,
  getMeteredDiscussionConfigEpic,
);
