import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { useStore } from 'react-redux';
import AzureAD, { MsalAuthProvider, AuthenticationState } from 'react-aad-msal';

export type Auth = firebase.User | null;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const Loader = () => (
  <SpinnerContainer>
    <Spin />
  </SpinnerContainer>
);

export const AuthProviderContext = React.createContext<MsalAuthProvider | undefined>(
  undefined,
);

const AuthSubscriber = ({
  children,
  authProvider,
}: {
  children: React.ReactChild;
  authProvider: MsalAuthProvider;
}) => {
  const store = useStore();

  return (
    <AuthProviderContext.Provider value={authProvider}>
      <AzureAD provider={authProvider} reduxStore={store}>
        {({ authenticationState }: { authenticationState: string }) =>
          authenticationState === AuthenticationState.InProgress ? (
            <Loader />
          ) : (
            <>{children}</>
          )
        }
      </AzureAD>
    </AuthProviderContext.Provider>
  );
};

export default AuthSubscriber;
