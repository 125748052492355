export const SUBSCRIBE = 'USER_TYPE/SUBSCRIBE';
export const SET_USER_TYPE = 'USER_TYPE/SET_USER_TYPE';
export const SET_AVAILABLE = 'USER_TYPE/SET_AVAILABLE';
export const SET_UNAVAILABLE = 'USER_TYPE/SET_UNAVAILABLE';
export const SEND_GENERAL_FEEDBACK = 'USER_TYPE/SEND_GENERAL_FEEDBACK';
export const SEND_FEEDBACK_SUCCESS = 'USER_TYPE/SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAILURE = 'USER_TYPE/SEND_FEEDBACK_FAILURE';
export const SET_SUPPORTED_TOPIC = 'USER_TYPE/SET_SUPPORTED_TOPIC';
export const UPDATE_NICKNAME = 'USER_TYPE/UPDATE_NICKNAME';
export const UPDATE_LAST_SEEN_INFO_BANNER = 'USER_TYPE/UPDATE_LAST_SEEN_INFO_BANNER';

export const ACCESS_REQUESTED = 'ACCESS/ACCESS_REQUESTED';
