import { combineEpics } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { switchMap, mergeMap, filter, catchError, tap, map } from 'rxjs/operators';
import { from, empty } from 'rxjs';
import { AuthenticationActions } from 'react-aad-msal';

import { catchHandler } from '../utils';

import { onResetPasswordRedirect } from './actions';

import { RootEpic } from '..';

const getCustomTokenCache = () => {
  try {
    const res = localStorage.getItem('@snapmentor/aad-idtoken');

    if (!res) return undefined;
    const cachedIdToken: { aadIdToken: string; fbCustomToken: string } = JSON.parse(res);
    return cachedIdToken;
  } catch (e) {
    return undefined;
  }
};

const setCustomTokenCache = (jwtIdToken: string, jwtCustomToken: string) => {
  localStorage.setItem(
    '@snapmentor/aad-idtoken',
    JSON.stringify({
      aadIdToken: jwtIdToken,
      fbCustomToken: jwtCustomToken,
    }),
  );
};

const getCustomTokenEpic: RootEpic = (action$, _, { backendService, auth }) =>
  action$.pipe(
    filter(isOfType(AuthenticationActions.LoginSuccess)),
    switchMap(({ payload: { jwtIdToken } }) => {
      const cachedIdToken = getCustomTokenCache();
      if (cachedIdToken && cachedIdToken.aadIdToken === jwtIdToken) {
        return from(auth.signInWithCustomToken(cachedIdToken.fbCustomToken)).pipe(
          mergeMap(() => empty()),
        );
      } else {
        return backendService.getCustomToken(jwtIdToken).pipe(
          tap(jwt => {
            setCustomTokenCache(jwtIdToken, jwt.customToken);
          }),
          mergeMap(jwt => from(auth.signInWithCustomToken(jwt.customToken))),
          mergeMap(() => empty()),
          catchError(catchHandler),
        );
      }
    }),
    catchError(catchHandler),
  );

const handlePasswordResetErrorEpic: RootEpic = (action$, _) =>
  action$.pipe(
    filter(isOfType(AuthenticationActions.LoginError)),
    map(({ payload: { errorMessage } }) => {
      if (errorMessage.includes('AADB2C90118')) {
        setTimeout(() => {
          window.location.href = `${window.location.origin}/forgot-password`;
        }, 500);
      }
      return onResetPasswordRedirect();
    }),
    catchError(catchHandler),
  );

export const rootAuthEpic = combineEpics(
  getCustomTokenEpic,
  handlePasswordResetErrorEpic,
);
